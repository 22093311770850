import React, { useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import config from "../common/config";
import Header from "./Header";
import LoginForm from "./LoginForm";
import $ from "jquery";
import { clockInclockOut, serviceList } from "../services/services";
import Modal from "react-modal";
import { GoogleMap, LoadScript, InfoBox, Marker } from "@react-google-maps/api";
import axios from "axios";
import { useForm } from "react-hook-form";
import moment from "moment";

const Exceptional = () => {
  const [unit, setUnit] = useState("");
  const [building, setBuilding] = useState("");
  const [property, setProperty] = useState("");
  const [location, setLocation] = useState("");
  const [serviceData, setServiceData] = useState();

  const [modalClockIn, setClockIn] = useState(false);
  const [defaultCenter, setDefaultCenter] = useState({
    lat: 19.20351,
    lng: 72.839233,
  });

  const isLogin = localStorage.getItem("isLogged");
  const user = JSON.parse(localStorage.getItem("user"));
  const isClockIn = JSON.parse(localStorage.getItem("isClockIn"));
  const requirement = localStorage.getItem("selectService");

  // var isClockin = localStorage.getItem("isClockin");

  const mapStyles = {
    height: "350px",
    width: "100%",
  };
  function openClockInModal(e) {
    e.preventDefault();
    setClockIn(true);
  }
  function closeClockInModal() {
    setClockIn(false);
  }
  function getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition);
    }
  }

  function convertUTCDateToLocalDate(date) {
    var newDate = new Date(
      date.getTime() + date.getTimezoneOffset() * 60 * 1000
    );

    var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();

    newDate.setHours(hours - offset);
    let dformat =
      [
        newDate.getFullYear(),
        newDate.getMonth() + 1 >= 10
          ? newDate.getMonth() + 1
          : "0" + (newDate.getMonth() + 1),
        newDate.getDate() >= 10 ? newDate.getDate() : "0" + newDate.getDate(),
      ].join("-") +
      " " +
      [
        newDate.getHours() >= 10
          ? newDate.getHours()
          : "0" + newDate.getHours(),
        newDate.getMinutes() >= 10
          ? newDate.getMinutes()
          : "0" + newDate.getMinutes(),
        newDate.getSeconds() >= 10
          ? newDate.getSeconds()
          : "0" + newDate.getSeconds(),
      ].join(":");
    return dformat;
  }

  var clockDate =
    user &&
    user.clocking_dtl &&
    (user.clocking_dtl.clock_in !== "" || user.clocking_dtl.clock_in !== null)
      ? convertUTCDateToLocalDate(new Date(user.clocking_dtl.clock_in))
      : "";
  console.log("clocking_dtl", clockDate);
  // var clockDate = new Date(
  //   utcToLocalIn
  //   ).toLocaleTimeString();
  async function showPosition(position) {
    setDefaultCenter((previousState) => {
      return {
        ...previousState,
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      };
    });
    var apiPromise = new Promise((resolve, reject) => {
      axios
        .get(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.coords.latitude},${position.coords.longitude}&key=AIzaSyCXREt_f6WG9lVFRpl0Nco8ZbmhzaXcoqM`
        )
        .then(async (response) => {
          if (
            response.data.ResponseCode == 1 ||
            response.data.ResponseCode == "1"
          ) {
            setLocation(response.data.results[0].formatted_address);
            localStorage.setItem(
              "location",
              response.data.results[0].formatted_address
            );
            return resolve(response.data.results[0].formatted_address);
          } else {
            localStorage.setItem(
              "location",
              response.data.results[0].formatted_address
            );
            // setLocation(response.data.results[0].formatted_address);
            return resolve(response.data.results[0].formatted_address);
          }
        })
        .catch((error) => {
          NotificationManager.error(error);
          return reject(error);
        });
    });

    return await apiPromise;
  }

  const ServiceList = async () => {
    var formData = new FormData();

    formData.append("user_id", user && user.manager_id);
    var apiResponse = await serviceList(formData);
    if (apiResponse.ResonseCode == 1 || apiResponse.ResponseCode == "1") {
      setServiceData(apiResponse.data);
    }
  };

  useEffect(() => {
    if(isLogin === "false" || isLogin === false || isLogin == null || isLogin == ""){
       return;
    }else{
      ServiceList(); 
    }
  }, []);
  // function showPosition(position) {
  //   setDefaultCenter((previousState) => {
  //     return {
  //       ...previousState,
  //       lat: position.coords.latitude,
  //       lng: position.coords.longitude,
  //     };
  //   });
  // }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const clockAgain = async () => {
    $("#homespin").removeAttr("hidden", "hidden");
    $(".hide-modal ").css("opacity", ".1");

    var formData = new FormData();

    const locate = localStorage.getItem("location");
    formData.append("user_id", user.user_id);
    formData.append("type", "clock_in");
    formData.append("building_name", building);
    formData.append("property_name", property);
    formData.append("location", locate);
    formData.append("unit_number", unit);
    formData.append("lat", defaultCenter.lat);
    formData.append("lng", defaultCenter.lng);

    const apiResponse = await clockInclockOut(formData);

    if (apiResponse.ResponseCode == 1 || apiResponse.ResponseCode == "1") {
      $("#homespin").attr("hidden", "hidden");
      $(".hide-modal ").css("opacity", "1");

      NotificationManager.success(apiResponse.ResponseMsg);
      const clockIn = apiResponse.data.clock_in_out_detail.map((e) => {
        return e.clock_in.split(" ");
      });

      const arrayLength = clockIn.length - 1;
      let newclockIn = convertUTCDateToLocalDate(
        new Date(clockIn[arrayLength])
      );
      localStorage.setItem("clockIn", newclockIn);
      localStorage.setItem(
        "isClockIn",
        JSON.stringify({
          is_clock_out: "0",
          is_clock_in: "1",
        })
      );
      // localStorage.setItem("clockIn", JSON.stringify(apiResponse.data));
      // window.location.reload();
    } else {
      $("#homespin").attr("hidden", "hidden");
      $(".hide-modal ").css("opacity", "1");
      NotificationManager.error(apiResponse.ResponseMsg);
    }
  };

  function convertUTCDateToLocalDate(date) {
    var newDate = new Date(
      date.getTime() + date.getTimezoneOffset() * 60 * 1000
    );

    var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();

    newDate.setHours(hours - offset);
    let dformat =
      [
        newDate.getFullYear(),
        newDate.getMonth() + 1 >= 10
          ? newDate.getMonth() + 1
          : "0" + (newDate.getMonth() + 1),
        newDate.getDate() >= 10 ? newDate.getDate() : "0" + newDate.getDate(),
      ].join("-") +
      " " +
      [
        newDate.getHours() >= 10
          ? newDate.getHours()
          : "0" + newDate.getHours(),
        newDate.getMinutes() >= 10
          ? newDate.getMinutes()
          : "0" + newDate.getMinutes(),
        newDate.getSeconds() >= 10
          ? newDate.getSeconds()
          : "0" + newDate.getSeconds(),
      ].join(":");
    return dformat;
  }
  const IsClockIn = () => {
    NotificationManager.error("User is already clockin");
  };
  return (
    <>
      <div className="home exception">
        <Header />
        <div id="home" className="home-container">
          <div className="row">
            <div className="col-lg-6 col-12">
              <div className="home-desc">
                <h6 className="message">PROFESSIONAL. INNOVATIVE. RELIABLE.</h6>
                <h1 className="large-text">Exceptional Service</h1>
                <h2 className="medium-text">Exceeding Expectations</h2>

                {isLogin === "false" ||
                isLogin === false ||
                isLogin == null ||
                isLogin == "" ? (
                  ""
                ) : user && (user.user_type === "Manager" || user.user_type === "Property Manager") ? (
                  ""
                ) : isClockIn &&
                  isClockIn.is_clock_in == isClockIn.is_clock_out ? (
                  <>
                    <p className="small-text">
                      Current Status <span>Clock Out</span>
                    </p>
                  </>
                ) : (
                  <>
                    <p className="small-text">
                      Current Status <span>Clock In</span>
                    </p>
                    <p className="small-text">
                      Time clocked in <span>{clockDate}</span>
                    </p>
                    <p className="small-text d-flex requirement-text">
                      Requirements to clock out{" "}
                      <ul>
                        {serviceData &&
                          serviceData.map((item, i) => {
                            if (
                              item.service === (user && user.clocking_dtl &&user.clocking_dtl.services)
                            ) {
                              let listLength = [];
                              return (
                                <div key={i}>
                                  {item &&
                                    item.sub_service.map((list, i) => {
                                      if (
                                        list.is_selected === "1" ||
                                        list.is_selected === 1
                                      ) {
                                        listLength.push(list.sub_id);
                                        return (
                                          <React.Fragment key={i}>
                                            <li>
                                              Upload <span>{list.name}</span>{" "}
                                              Photo
                                            </li>
                                          </React.Fragment>
                                        );
                                      }
                                    })}

                                  {item &&
                                    item.sub_service.map((list, i) => {
                                      if (listLength.length === 0) {
                                        return (
                                          <React.Fragment key={i}>
                                            <li>
                                              Upload <span>{list.name}</span>
                                            </li>
                                          </React.Fragment>
                                        );
                                      }
                                    })}
                                </div>
                              );
                            }
                          })}
                      </ul>
                    </p>
                  </>
                )}

                {/* {
                  isLogin === "false" ||
                  isLogin === false ||
                  isLogin == null ||
                  isLogin == "" ? (
                    ""
                  ) : user && user.user_type === "Manager" ? (
                    ""
                  ) : isClockIn && isClockIn.is_clock_in == isClockIn.is_clock_out ? (
                    <div className="select" onClick={openClockInModal}>
                      Clock in Again
                      <span>
                        <img
                          className="plus"
                          src={config.appBaseName + "images/plus.png"}
                          alt=""
                        />
                      </span>
                    </div>
                  ) : (
                    <div className="select" onClick={IsClockIn}>
                      Clock in Again
                      <span>
                        <img
                          className="plus"
                          src={config.appBaseName + "images/plus.png"}
                          alt=""
                        />
                      </span>
                    </div>
                  )
                  
                } */}
              </div>
            </div>
            <div className="col-lg-6 col-12 mt-lg-0 contact-form d-flex flex-column">
              <LoginForm />
            </div>
          </div>
        </div>
      </div>
      <div className="map-modal">
        <Modal
          ariaHideApp={false}
          isOpen={modalClockIn}
          contentLabel="Example Modal"
        >
          <div id="homespin" className="spinner " role="status" hidden>
            <span className="spinner-border"></span>
          </div>
          <div className="map-modal hide-modal">
            <img
              src={config.appBaseName + "images/close-btn.png"}
              onClick={closeClockInModal}
              className="modal-close"
              alt=""
            />
            <div className="g-map">
              <GoogleMap
                mapContainerStyle={mapStyles}
                zoom={13}
                center={defaultCenter}
              >
                <Marker
                  icon={config.appBaseName + "images/pin.png"}
                  key="marker_1"
                  position={defaultCenter}
                />
              </GoogleMap>
            </div>
            <div className="select" onClick={getLocation}>
              Use My Current Location
              <span>
                <img
                  className="plus"
                  src={config.appBaseName + "images/white-plus.png"}
                  alt=""
                />
              </span>
            </div>

            <div className="modal-form login-form ">
              <form onSubmit={handleSubmit(clockAgain)}>
                <input
                  id="property"
                  type="text"
                  name="property_name"
                  placeholder="Property Name"
                  value={property}
                  onChange={(e) => setProperty(e.target.value)}
                  required
                />
                <input
                  id="building"
                  type="text"
                  name="building_name"
                  placeholder="Building Number"
                  value={building}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  onChange={(e) => setBuilding(e.target.value)}
                  required
                />
                <input
                  id="unit"
                  type="text"
                  placeholder="Unit Number"
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  name="unit_number"
                  value={unit}
                  onChange={(e) => setUnit(e.target.value)}
                  required
                />

                <button type="submit" className="select">
                  Clock In
                  <span>
                    <img
                      className="plus"
                      src={config.appBaseName + "images/white-plus.png"}
                      alt=""
                    />
                  </span>
                </button>
              </form>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default Exceptional;
