import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./screens/Home";
import About from "./screens/About";
import Testimonial from "./screens/Testimonial";
import Contact from "./screens/Contact";
import Test from "./components/Test";
import Profile from "./components/Profile";
import Service from "./components/Service";
import { NotificationContainer } from "react-notifications";
function App() {
  return (
    <>
      <div id="logoutSpin" className="spinner " role="status" hidden>
        <span className="spinner-border"></span>
      </div>
      <BrowserRouter basename="/">
        <Routes>
          <Route exact path="/" element={<Home />} />
          {/* <Route exact path="/about" element={<About />} />
          <Route exact path="/testimonial" element={<Testimonial />} />
          <Route exact path="/contact" element={<Contact />} /> */}
          <Route exact path="/test" element={<Test />} />
        </Routes>
      </BrowserRouter>
      <NotificationContainer />
    </>
  );
}

export default App;
