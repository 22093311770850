import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import config from "../common/config";
import ProfileDropDown from "./ProfileDropDown";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import $ from "jquery";
import { NotificationManager } from "react-notifications";

const Header = () => {
  const [visible, setVisible] = useState(false);
  const [isSeeker, setIsSeeker] = useState(false);
  const [navbar, setNavbar] = useState(false);
  const [login] = useState(false);
  const [userType, setUserType] = useState("");

  const [selectedOption, setSelectedOption] = useState({ value: "Employee" });

  localStorage.setItem("user_type", selectedOption.value);

  const islogin = localStorage.getItem("isLogged");
  const user = JSON.parse(localStorage.getItem("user"));
  const options = [
    { value: "Manager", label: "Manager" },
    { value: "Employee", label: "Employee" },
  ];

  useEffect(() => {
    $(".css-14el2xx-placeholder").text("Employee");
    $(".css-14el2xx-placeholder").html("Employee");

    $(".navbar-toggler").click(function () {
      if ($("div.main-header").hasClass("whitenavbar") == false) {
        $("div.main-header").addClass("whitenavbar");
      } else {
        $("div.main-header").removeClass("whitenavbar");
      }
    });
  }, []);
  var isLogin = localStorage.getItem("isLogged");
  const navigate = useNavigate();
  const menuToggle = () => {
    setVisible(!visible);
  };
  const logout = () => {
    localStorage.setItem("isLogged", false);
    navigate("/");
    window.location.reload();
  };

  const changeBackground = () => {
    if (window.scrollY >= 80) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  window.addEventListener("scroll", changeBackground);

  return (
    <>
      <div className={navbar ? "main-header active-scroll" : "main-header"}>
        <nav className="navbar navbar-expand-lg navbar-dark">
          <div className="container-fluid">
            {/* {isLogin == true ?<a className="navbar-brand" href="#"> */}
            <img
              src="https://cortland.appsgenic.com/ServiceProvider/public/images/logo.png"
              className="nav-logo"
              alt=""
            />
            {/* </a>:NotificationManager("please login first")} */}

            <div className="d-flex">
              {islogin && (islogin === true || islogin === "true") ? (
                <>
                  <div
                    style={{ pointerEvents: "none" }}
                    className="op-select d-flex d-lg-none"
                  >
                    <Select
                      defaultValue={{ label: user && user.user_type, value: 0 }}
                      // options={options}
                    />
                  </div>
                  <div className="profile d-flex d-lg-none">
                    <div className="profile-pic">
                      <ProfileDropDown />
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}

              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon" />
              </button>
            </div>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              {isLogin == null ||
              isLogin == "null" ||
              isLogin == "false" ||
              isLogin == "" ? (
                <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                  <li className="nav-item">
                    <NavLink
                      className={() =>
                        window.location.hash === "#home" ? "active-nav" : ""
                      }
                      aria-current="page"
                      to="/#home"
                      onClick={() => {
                        window.location.replace("#home");
                      }}
                    >
                      Home
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      className={() =>
                        window.location.hash === "#contact" ? "active-nav" : ""
                      }
                      aria-current="page"
                      to="#contact"
                      onClick={() => {
                        window.location.replace("#contact");
                      }}
                    >
                      Contact US
                    </NavLink>
                  </li>
                </ul>
              ) : user && (user.user_type == "Manager" || user.user_type == "Property Manager") ? (
                <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                  <li className="nav-item">
                    <NavLink
                      className={() =>
                        window.location.hash === "#home" ? "active-nav" : ""
                      }
                      aria-current="page"
                      to="/#home"
                      onClick={() => {
                        window.location.replace("#home");
                      }}
                    >
                      Home
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      className={() =>
                        window.location.hash === "#profile" ? "active-nav" : ""
                      }
                      aria-current="page"
                      to="#profile"
                      onClick={() => {
                        window.location.replace("#profile");
                      }}
                    >
                      My Profile
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      className={() =>
                        window.location.hash === "#services" ? "active-nav" : ""
                      }
                      aria-current="page"
                      to="#services"
                      onClick={() => {
                        window.location.replace("#services");
                      }}
                    >
                      My Service
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      className={() =>
                        window.location.hash === "#contact" ? "active-nav" : ""
                      }
                      aria-current="page"
                      to="#contact"
                      onClick={() => {
                        window.location.replace("#contact");
                      }}
                    >
                      Contact US
                    </NavLink>
                  </li>
                </ul>
              ) : (
                <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                  <li className="nav-item">
                    <NavLink
                      className={() =>
                        window.location.hash === "#home" ? "active-nav" : ""
                      }
                      aria-current="page"
                      to="/#home"
                      onClick={() => {
                        window.location.replace("#home");
                      }}
                    >
                      Home
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      className={() =>
                        window.location.hash === "#profile" ? "active-nav" : ""
                      }
                      aria-current="page"
                      to="#profile"
                      onClick={() => {
                        window.location.replace("#profile");
                      }}
                    >
                      My Profile
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      className={() =>
                        window.location.hash === "#contact" ? "active-nav" : ""
                      }
                      aria-current="page"
                      to="#contact"
                      onClick={() => {
                        window.location.replace("#contact");
                      }}
                    >
                      Contact US
                    </NavLink>
                  </li>
                </ul>
              )}

              {islogin && (islogin === true || islogin === "true") ? (
                <>
                  <div
                    style={{ pointerEvents: "none" }}
                    className="op-select d-lg-block d-none "
                  >
                    <Select
                      defaultValue={{ label: user && user.user_type, value: 0 }}
                      // options={options}
                    />
                  </div>
                  <div className="profile d-none d-lg-flex">
                    <div className="profile-pic">
                      <ProfileDropDown />
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </nav>
      </div>
      {/* <hr /> */}
    </>
  );
};

export default Header;
