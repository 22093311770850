import React, { useState } from "react";
import { NotificationManager } from "react-notifications";
import OutsideClickHandler from "react-outside-click-handler";
import { NavLink, useNavigate } from "react-router-dom";
import Modal from "react-modal";
import config from "../common/config";
import { changePassword, LogOut } from "../services/services";
import $ from "jquery";
import { useForm } from "react-hook-form";

const ProfileDropDown = () => {
  const navigate = useNavigate();
  const [passwordModal, setPasswordModal] = useState(false);

  const [visible, setVisible] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));
  const isLogIn = localStorage.getItem("isLogged");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  function openPassModal() {
    setPasswordModal(true);
  }
  function closePassModal() {
    setPasswordModal(false);
  }
  const validPassword = new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
  );
  const logout = async (e) => {
    $("#logoutSpin").removeAttr("hidden", "hidden");
    $("body").css("opacity", ".1");
    $(".select").css("pointer-events", "none");
    e.preventDefault();
    var formData = new FormData();
    formData.append("user_id", user.user_id);
    const apiResponse = await LogOut(formData);
    if (apiResponse.ResonseCode == 1 || apiResponse.ResponseCode == "1") {
      $("#logoutSpin").attr("hidden", "hidden");
      $("body").css("opacity", "1");
      $(".select").css("pointer-events", "fill");
      NotificationManager.success(apiResponse.ResponseMsg);
      localStorage.clear();
      localStorage.setItem("isLogged", false);
      window.location.replace(config.appBaseName);
    } else {
      $("#logoutSpin").attr("hidden", "hidden");
      $("body").css("opacity", "1");
      $(".select").css("pointer-events", "fill");
      NotificationManager.error(apiResponse.ResponseMsg);
    }
  };

  const handleResetPass = async (data) => {
    $("#loadSpin").removeAttr("hidden", "hidden");
    $(".edit-row ").css("opacity", ".1");
    $(".select").css("pointer-events", "none");

    if (!data.new_password.trim) {
      $("#loadSpin").attr("hidden", "hidden");
      $(".select").css("pointer-events", "fill");
      $(".edit-row ").css("opacity", "1");
      NotificationManager.error("Enter Password");
    } else if (
      !data.new_password.trim() ||
      validPassword.test(data.new_password) === false
    ) {
      $("#loadSpin").attr("hidden", "hidden");
      $(".select").css("pointer-events", "fill");
      $(".edit-row ").css("opacity", "1");
      NotificationManager.error(
        "Please enter a Valid Password that contains 1 lowercase, Uppercase, Number, Symbol, and password length must be more than 8 "
      );
      return false;
    }

    if (data.new_password !== data.confirm_pass) {
      $("#loadSpin").attr("hidden", "hidden");
      $(".edit-row ").css("opacity", "1");
      $(".select").css("pointer-events", "fill");
      NotificationManager.error(
        "New Password and Confirm Password does not Match"
      );
    } else {
      var formData = new FormData();
      var md5 = require("md5");

      Object.keys(data).forEach((key) => {
        if (key == "old_password") {
          var oldval = md5(data[key]);
          formData.append(key, oldval);
        }else if (key == "new_password") {
          var newval = md5(data[key]);
          formData.append(key, newval);
        } else {
          formData.append(key, data[key]);
        }
      });

      formData.append("user_id", user.user_id);
      var apiResponse = await changePassword(formData);
      if (apiResponse.ResponseCode == 1 || apiResponse.ResponseCode == "1") {
        $("#loadSpin").attr("hidden", "hidden");
        $(".edit-row ").css("opacity", "1");
        $(".select").css("pointer-events", "fill");

        NotificationManager.success(apiResponse.ResponseMsg);
        localStorage.clear();
        localStorage.setItem("isLogged", false);
        window.location.replace(config.appBaseName);
      } else {
        $("#loadSpin").attr("hidden", "hidden");
        $(".edit-row ").css("opacity", "1");
        $(".select").css("pointer-events", "fill");
        NotificationManager.error(apiResponse.ResponseMsg);
      }
    }
  };
  return (
    <>
      {isLogIn === "false" ||
      isLogIn === false ||
      isLogIn === null ||
      isLogIn === "" ? (
        <img
          onClick={() => window.location.replace(config.appBaseName)}
          className="plus"
          src={config.appBaseName + "images/user.png"}
          alt=""
        />
      ) : (
        <>
          <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
            <div className="profile-position">
              <div
                className="main-profile"
                onClick={() => setVisible(!visible)}
              >
                {visible && (
                  <div className="head-profile">
                    <div className="profile-body">
                      <div className=" edit-profile-body">
                        <div className="profile-item">
                          <NavLink
                            aria-current="page"
                            to="#profile"
                            onClick={() => {
                              window.location.replace(config.appBaseName + "#profile");
                            }}
                          >
                            Update Profile
                          </NavLink>
                          <button type="submit" onClick={openPassModal}>
                            Change Password
                          </button>
                          {isLogIn &&
                          (isLogIn === "true" || isLogIn === true) ? (
                            <button type="submit" onClick={logout}>
                              LogOut
                            </button>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {user && user.profile && user.profile != "" ? (
                  <img className="plus" src={user.profile} alt="" />
                ) : (
                  <img
                    className="plus"
                    src={config.appBaseName + "images/user.png"}
                    alt=""
                  />
                )}

                {/* {visible && ( */}

                {/* )} */}
              </div>
            </div>
          </OutsideClickHandler>
          <div className="map-modal">
            <Modal
              ariaHideApp={false}
              isOpen={passwordModal}
              id="password-modal"
              contentLabel="password Modal"
            >
              <div id="loadSpins" className="spinner " role="status" hidden>
                <span className="spinner-border"></span>
              </div>
              <img
                src={config.appBaseName + "images/close-btn.png"}
                onClick={closePassModal}
                className="modal-close"
                alt=""
              />
              <div className="map-modal upload-modal hide-modal">
                <div className="modal-form login-form ">
                  <div className="login-form">
                    <form onSubmit={handleSubmit(handleResetPass)}>
                      <input
                        className="mb-4"
                        type="password"
                        name="password"
                        autoComplete="off"
                        placeholder="Enter Old Password"
                        {...register("old_password", {
                          required: "This is required.",
                          maxLength: 80,
                        })}
                      />

<input
                        className="mb-4"
                        type="password"
                        name="password"
                        autoComplete="off"
                        placeholder="Enter New Password"
                        {...register("new_password", {
                          required: "This is required.",
                          maxLength: 80,
                        })}
                      />
                      <input
                        type="text"
                        autoComplete="off"
                        name="confirm_pass"
                        placeholder="Confirm Your Password"
                        {...register("confirm_pass", {
                          required: "This is required.",
                          maxLength: 80,
                        })}
                      />

                      <button type="text" className="select">
                        Submit
                        <span>
                          <img
                            className="plus"
                            src={config.appBaseName + "images/white-plus.png"}
                            alt=""
                          />
                        </span>
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </Modal>
          </div>
        </>
      )}
    </>
  );
};

export default ProfileDropDown;
