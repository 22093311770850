import React, { forwardRef, useEffect, useState } from "react";
import moment from "moment";
import { exportPDFFile, getEmployeeDetail } from "../../services/services";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import config from "../../common/config";
import Slider from "react-slick";
import Pagination from "../Pagination";
import $ from "jquery";
import { NotificationManager } from "react-notifications";
import axios from "axios";

const Service = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(5);
  const [employeeDtl, setEmployeeDtl] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [month, setMonth] = useState(moment(startDate).format("MM"));
  const [year, setYear] = useState("");
  const [pdf, setPdf] = useState();
  const [empPdf, setempPdf] = useState();

  const user = JSON.parse(localStorage.getItem("user"));
  const isLogin = localStorage.getItem("isLogged");
  //   const emp_clock_id = localStorage.getItem("emp_clock_id");

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <button className="date-picker-button" onClick={onClick} ref={ref}>
      {value}
    </button>
  ));

  const [imageIndex, setImageIndex] = useState(0);

  const settings = {
    infinite: false,
    lazyLoad: true,
    dots: false,
    speed: 300,
    slidesToShow: 5,
    slidesToScroll:1,
    centerMode: true,
    centerPadding: 0,
    beforeChange: (current, next) => setImageIndex(next),
    responsive: [
      {
        breakpoint: 1700,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          // initialSlide: 1
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // const fetchLocationName = async (lat, lng) => {
  //   var apiPromise = new Promise((resolve, reject) => {
  //     axios
  //       .get(
  //         `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyCXREt_f6WG9lVFRpl0Nco8ZbmhzaXcoqM`
  //       )
  //       .then(async (response) => {
  //         if (
  //           response.data.ResponseCode == 1 ||
  //           response.data.ResponseCode == "1"
  //         ) {
  //           return resolve(response.data.results[0].formatted_address);
  //         } else {
  //           return resolve(response.data.results[0].formatted_address);
  //         }
  //       })
  //       .catch((error) => {
  //         NotificationManager.error(error);
  //         return reject(error);
  //       });
  //   });
  //   return await apiPromise;
  // };

  // posts.forEach(async (element) => {
  //   // console.log("element", element);
  //   var lat = element.lat;
  //   var lng = element.lng;
  //   // console.log(lat);

  //   element.location = await fetchLocationName(lat, lng);
  //   // console.log("lat, lng", fetchLocationName(lat, lng));
  // });

  const EmployeeDetail = async () => {
    var formData = new FormData();
    formData.append("user_id", user && user.user_id);
    formData.append("month", month);
    formData.append("year", year);
    var apiResponse = await getEmployeeDetail(formData);
    if (apiResponse.ResonseCode == 1 || apiResponse.ResponseCode == "1") {
      setEmployeeDtl(apiResponse.data);
      setLoading(true);
      setPosts(apiResponse.data);
      setLoading(false);
    }
  };

  const handleDataPicker = (data) => {
    setStartDate(data);
    setMonth(moment(data).format("MM"));
    setYear(moment(data).format("YYYY"));
    // EmployeeDetail();
  };

  function convertUTCDateToLocalDate(date) {
    var newDate = new Date(
      date.getTime() + date.getTimezoneOffset() * 60 * 1000
    );

    var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();

    newDate.setHours(hours - offset);
    let dformat =
      [
        newDate.getFullYear(),
        newDate.getMonth() + 1 >= 10
          ? newDate.getMonth() + 1
          : "0" + (newDate.getMonth() + 1),
        newDate.getDate() >= 10 ? newDate.getDate() : "0" + newDate.getDate(),
      ].join("-") +
      " " +
      [
        newDate.getHours() >= 10
          ? newDate.getHours()
          : "0" + newDate.getHours(),
        newDate.getMinutes() >= 10
          ? newDate.getMinutes()
          : "0" + newDate.getMinutes(),
        newDate.getSeconds() >= 10
          ? newDate.getSeconds()
          : "0" + newDate.getSeconds(),
      ].join(":");
    return dformat;
  }

  const exportPdf = async () => {
    $("#loadService").removeAttr("hidden", "hidden");
    $(".opacity-service ").css("opacity", ".1");
    $(".pdf").css("pointer-events", "none");

    var formData = new FormData();
    formData.append("user_id", user && user.user_id);

    var apiResponse = await exportPDFFile(formData);

    if (apiResponse.ResonseCode == 0 || apiResponse.ResponseCode == "0") {
      $("#loadService").attr("hidden", "hidden");
      $(".opacity-service ").css("opacity", "1");
      $(".pdf").css("pointer-events", "fill");
      NotificationManager.error(apiResponse.ResponseMsg);
    } else if (
      apiResponse.ResonseCode == 1 ||
      apiResponse.ResponseCode == "1"
    ) {
      $("#loadService").attr("hidden", "hidden");
      $(".opacity-service ").css("opacity", "1");
      $(".pdf").css("pointer-events", "fill");
      //   await setPdf(apiResponse.data);
      // window.location.href = apiResponse.data;
      window.open(
        apiResponse.data,
        '_blank' // <- This is what makes it open in a new window.
      );
      
    } else {
      $("#loadService").attr("hidden", "hidden");
      $(".opacity-service ").css("opacity", "1");
      $(".pdf").css("pointer-events", "fill");
      NotificationManager.error(apiResponse.ResponseMsg);
    }
  };

const [empClockId,setEmpClockId] =useState("");
  $(".serviceName").each(function (i, obj) {
    $(this).click(function () {
      let id = $(this).attr("data-bs-target");
      setEmpClockId(parseInt(id.replace(/[^0-9]/gi, "")))
      // emp_clock_id = parseInt(id.replace(/[^0-9]/gi, ""));
      // localStorage.setItem("emp_clock_id", emp_clock_id);
    });
  });

  const exportEmpPdf = async () => {
    $("#loadService").removeAttr("hidden", "hidden");
    $(".opacity-service ").css("opacity", ".1");
    $(".pdf").css("pointer-events", "none");

    var formData = new FormData();
    formData.append("user_id", user && user.user_id);
    formData.append("clock_id", empClockId);
    var apiResponse = await exportPDFFile(formData);
    if (apiResponse.ResonseCode == 0 || apiResponse.ResponseCode == "0") {
      $("#loadService").attr("hidden", "hidden");
      $(".opacity-service ").css("opacity", "1");
      $(".pdf").css("pointer-events", "fill");
      NotificationManager(apiResponse.ResponseMsg);
    } else if (
      apiResponse.ResonseCode == 1 ||
      apiResponse.ResponseCode == "1"
    ) {
      $("#loadService").attr("hidden", "hidden");
      $(".opacity-service ").css("opacity", "1");
      $(".pdf").css("pointer-events", "fill");
      // window.location.href = apiResponse.data;
      window.open(
        apiResponse.data,
        '_blank' // <- This is what makes it open in a new window.
      );
    } else {
      $("#loadService").attr("hidden", "hidden");
      $(".opacity-service ").css("opacity", "1");
      $(".pdf").css("pointer-events", "fill");
      NotificationManager(apiResponse.ResponseMsg);
    }
  };

  // Get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);
  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  useEffect(() => {
    EmployeeDetail();
    // setAddress(fetchLocationName(21.2238336, 72.8530944));
  }, [startDate]);

  const [searchList, setSearchList] = useState("");

  function search(rows) {
    console.log("rows", rows);
    return rows.filter(
      (row) => 
      (row.created_date && row.created_date.toLowerCase().indexOf(searchList.toLowerCase())) > -1 ||
      (row.user_detail && row.user_detail[0].fullname.toLowerCase().indexOf(searchList.toLowerCase())) > -1 ||
      (row.building_name && row.building_name.toLowerCase().indexOf(searchList.toLowerCase())) > -1 ||
      (row.property_name && row.property_name.toLowerCase().indexOf(searchList.toLowerCase())) > -1 ||
      (row.location && row.location.toLowerCase().indexOf(searchList.toLowerCase())) > -1 ||
      (row.unit_number && row.unit_number.toString().indexOf(searchList.toLowerCase())) > -1 ||
      (row.status && row.status.toLowerCase().indexOf(searchList.toLowerCase())) > -1
    );
  }

  return (
    <>
      {user && user.user_type === "Property Manager" ? (
        <div id="services" className="" style={{ position: "relative" }}>
          <div id="loadService" className="spinner " role="status" hidden>
            <span className="spinner-border"></span>
            
          </div>
          <section className="opacity-service manager-profile">
            <div className="container">
              <div className="row">
                <div className="col-12 col-md-8 text-center profile-section align-self-center">
                  <div className="d-flex flex-wrap">
                    <div className=" profile-img align-self-center">
                      {user && user.profile && user.profile != "" ? (
                        <img
                          src={user.profile}
                          className="img-fluid d-block "
                          alt=""
                        />
                      ) : (
                        <img
                          src={config.appBaseName + "images/profile.png"}
                          className="img-fluid d-block "
                          alt=""
                        />
                      )}
                    </div>
                    <div className="employee-name align-self-center">
                      <h3>
                        {user && user.fullname !== null
                          ? user.fullname
                          : "User"}
                      </h3>
                      <h3 className="manager-id">
                        {user && user.manager_number !== null
                          ? user.manager_number
                          : "1234567890"}
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-4 align-self-center">
                  <div className="employee-id">
                    <div className="date-picker">
                      <DatePicker
                        selected={startDate}
                        onChange={handleDataPicker}
                        dateFormat="MMM yyyy"
                        customInput={<ExampleCustomInput />}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="opacity-service manager-detail">
            <div className="my-container">
               <input
                  type="search"
                  className="search-input"
                  placeholder="Search"
                  value={searchList}
                  onChange={(e) => setSearchList(e.target.value)}
                />
              <div className="detail-table">
               
                <table
                  className="table table-striped table-hover text-center"
                  id="table_list"
                >
                  <thead className="">
                    <tr>
                      <th scope="col">
                        <span>Date</span>
                      </th>
                      <th scope="col">
                        <span>
                          Employee
                          <br />
                          Name
                        </span>
                      </th>
                      <th scope="col">
                        <span>
                          Clock in <br /> Time
                        </span>
                      </th>
                      <th scope="col">
                        <span>
                          Building <br /> Name
                        </span>
                      </th>
                      <th scope="col">
                        <span>
                          Property <br /> Name
                        </span>
                      </th>
                      <th scope="col">
                        <span>
                          Employee <br /> Location
                        </span>
                      </th>
                      <th scope="col">
                        <span>
                          Unit <br /> Number
                        </span>
                      </th>
                      <th scope="col">
                        <span>
                          Service <br /> Photo
                        </span>
                      </th>
                      <th scope="col">
                        <span>
                          Clock Out <br /> Time
                        </span>
                      </th>
                      <th scope="col">
                        <span>Status</span>
                      </th>
                    </tr>
                  </thead>
                  {employeeDtl && employeeDtl.length > 0 ? (
                    <tbody>
                      {search(currentPosts).map((data, i) => {
                        // date
                        var date = moment(data.created_date).format( "MMM DD,YYYY" ); 

                        // clockInDate
                        var utcToLocalIn = convertUTCDateToLocalDate( new Date(data.clock_in) );
                        // var clockInDate = new Date( utcToLocalIn ).toLocaleTimeString();
                        var clockInDate = moment.utc(data.clock_in).local().format("hh:mm:ss a");

                        // clockOutdate
                        var utcToLocalOut = convertUTCDateToLocalDate( new Date(data.clock_out) );
                        // var clockOut = new Date( utcToLocalOut ).toLocaleTimeString();
                        var clockOut = moment.utc(data.clock_out).local().format("hh:mm:ss a");

                        return (
                          <tr key={i}>
                            <td>
                              <p id="date">{date}</p>
                            </td>
                            <td>
                              <button
                                type="button"
                                className="serviceName"
                                data-bs-toggle="modal"
                                data-bs-target={`#service${data.clock_id}`}
                              >
                                {data && data.user_detail == ""
                                  ? ""
                                  : data &&
                                    data.user_detail &&
                                    data.user_detail.length &&
                                    data.user_detail.map((data, key) => {
                                      return <p key={key}>{data.fullname}</p>;
                                    })}
                              </button>
                            </td>
                            <td>
                              <p>
                                {data.clock_in == null ||
                                  data.clock_in == "" ||
                                  data.clock_in == "Invalid date"
                                  ? ""
                                  : clockInDate}
                                {/* {clockInDate} */}
                              </p>
                            </td>
                            <td>
                              <p>{data.building_name}</p>
                            </td>
                            <td>
                              <p
                                className="emp_location"
                                title={data.property_name}
                              >
                                {data.property_name}
                              </p>
                            </td>
                            <td>
                              <p className="emp_location" title={data.location}>
                              {data.location ==null || data.location =="null"?"-":data.location}
                              </p>
                            </td>
                            <td>
                              <p>{data.unit_number}</p>
                            </td>
                            <td>
                              <p className="Work-img">
                                {data && data.work_image == ""
                                  ? ""
                                  : data &&
                                    data.work_image &&
                                    data.work_image.length &&
                                    data.work_image.map((data, key) => {
                                      return (
                                        <img
                                          key={key}
                                          className="img-fluid d-block"
                                          src={data.image}
                                          alt=""
                                        />
                                      );
                                    })}
                              </p>
                            </td>
                            <td>
                              <p>
                                {data.clock_out == null ||
                                  data.clock_out == "null" ||
                                  data.clock_out == "" ||
                                  data.clock_out == "Invalid date"
                                  ? ""
                                  : clockOut}
                              </p>
                            </td>
                            <td>
                              <p
                                className={
                                  "status " +
                                  (data.status === "Success"
                                    ? "success"
                                    : "pending")
                                }
                              >
                                {data.status}
                              </p>
                            </td>
                          </tr>
                        );
                      })}
                      {/* <Posts posts={currentPosts} loading={loading} /> */}
                    </tbody>
                  ) : (
                    <tbody className="noData">
                      <h5>No Data Found</h5>
                    </tbody>
                  )}
                </table>
              </div>
              <Pagination
                postsPerPage={postsPerPage}
                totalPosts={posts.length}
                currentPage={currentPage}
                paginate={paginate}
              />
            </div>
          </section>
          <section className="opacity-service text-center">
            {/* <a id="download" href={config.appBaseName + "export.pdf"} download>Download</a> */}
            <div className="my-btn">
              <button
                className="btn-property pdf btn-blue btn btn-primary"
                id="exportPdfList"
                // href=""
                // download
                // rel="noreferrer"
                // target="_blank"
                onClick={exportPdf}
              >
                Export PDF
                <span className="corner">
                  <img
                    className="plus"
                    src={config.appBaseName + "images/white-plus.png"}
                    alt=""
                  />
                </span>
              </button>

              <button
                className="btn-property pdf  btn btn-primary"
                id="printPdfList"
                // href={pdf}
                // download
                // rel="noreferrer"
                // target="_blank"
                onClick={exportPdf}
              >
                Print
                <span className="corner">
                  <img
                    className="plus"
                    src={config.appBaseName + "images/white-plus.png"}
                    alt=""
                  />
                </span>
              </button>
            </div>
          </section>
        </div>
      ) : (
        ""
      )}
      {posts &&
        posts.map((data, i) => {
          // clockIn
          var utcToLocalIn = convertUTCDateToLocalDate(new Date(data.clock_in));
          var clockInDate = moment.utc(data.clock_in).local().format("hh:mm:ss a");
          // var clockInDate = new Date( utcToLocalIn ).toLocaleTimeString();
          // var clockIn = moment(utcToLocalIn).format("h:mm:ss");

          var utcToLocalOut = convertUTCDateToLocalDate( new Date(data.clock_out) );
          var clockOut = moment.utc(data.clock_out).local().format("hh:mm:ss a");
          // var clockOut = new Date( utcToLocalOut ).toLocaleTimeString();
          // var clockOut = moment(utcToLocalOut).format("h:mm:ss");
          return (
            <div
              key={i}
              className="modal fade opacity-service"
              id={`service${data.clock_id}`}
              tabIndex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered ">
                <div className="modal-content ReactModal__Content">
                  <div className="modal-header justify-content-end border-0">
                    <button
                      type="button"
                      className="btn p-0"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      <img
                        src={config.appBaseName + "images/close-btn.png"}
                        className="modal-close"
                        alt=""
                      />
                    </button>
                  </div>
                  <div className="modal-body ps-0 pe-0">
                    <div className="slider">
                      <div className="App">
                        {data &&
                        data.work_image &&
                        data.work_image.length > 0 ? (
                          <Slider {...settings}>
                            {data && data.work_image == ""
                              ? ""
                              : data &&
                                data.work_image &&
                                data.work_image.length &&
                                data.work_image.map((data, key) => {
                                  return (
                                    <div
                                      key={key}
                                      className={
                                        key === imageIndex
                                          ? "slide activeSlide"
                                          : "slide"
                                      }
                                    >
                                      <>
                                        <img src={data.image} alt="" />
                                        <div className="mybox"></div>
                                      </>
                                    </div>
                                  );
                                })}
                          </Slider>
                        ) : 
                        // data && data.work_image == "" ? (
                          ""
                        // ) 
                        // : (
                        //   data.work_image.map((data, key) => {
                        //     return (
                        //       <div
                        //         key={key}
                        //         className={
                        //           key === imageIndex
                        //             ? "slide activeSlide"
                        //             : "slide"
                        //         }
                        //       >
                        //         <>
                        //           <img src={data.image} alt="" />
                        //         </>
                        //       </div>
                        //     );
                        //   })
                        // )
                        }
                      </div>
                    </div>
                    <div className="exported-detail">
                      <table>
                        <tbody>
                          <tr>
                            <td>Employee Name </td>
                            <td className="colon">:</td>
                            <td>
                              {data && data.user_detail == ""
                                ? ""
                                : data &&
                                  data.user_detail &&
                                  data.user_detail.length &&
                                  data.user_detail.map((data, key) => {
                                    return <p key={key}>{data.fullname}</p>;
                                  })}
                            </td>
                          </tr>
                          <tr>
                            <td>Employee Phone No.</td>
                            <td className="colon">:</td>
                            <td>
                              {data && data.user_detail == ""
                                ? ""
                                : data &&
                                  data.user_detail &&
                                  data.user_detail.length &&
                                  data.user_detail.map((data, key) => {
                                    return <p key={key}>{data.phone}</p>;
                                  })}
                            </td>
                          </tr>
                          <tr>
                            <td>Employee Clock In</td>
                            <td className="colon">:</td>
                            <td>
                              {data.clock_in === null ||
                                data.clock_in === "" ||
                                data.clock_in == "Invalid date"
                                ? ""
                                : clockInDate}
                            </td>
                          </tr>
                          <tr>
                            <td>Employee Location</td>
                            <td className="colon">:</td>
                            <td className="emp_location" title={data.location}>
                            {data.location ==null || data.location =="null"?"-":data.location}
                            </td>
                          </tr>
                          <tr>
                            <td>Building Name</td>
                            <td className="colon">:</td>
                            <td>{data.building_name}</td>
                          </tr>
                          <tr>
                            <td>Property Name</td>
                            <td className="colon">:</td>
                            <td>{data.property_name}</td>
                          </tr>
                          <tr>
                            <td>Unit Number</td>
                            <td className="colon">:</td>
                            <td>{data.unit_number}</td>
                          </tr>
                          {/* <tr>
                            <td>Employee Service</td>
                            <td className="colon">:</td>
                            <td>
                              {data && data.user_detail == ""
                                ? ""
                                : data &&
                                  data.user_detail &&
                                  data.user_detail.length &&
                                  data.user_detail.map((data, key) => {
                                    return <p key={key}>{data.services}</p>;
                                  })}
                            </td>
                          </tr> */}
                          <tr>
                            <td>Employee Clock Out</td>
                            <td className="colon">:</td>
                            <td>
                              {data.clock_out === null ||
                                data.clock_out === "" ||
                                data.clock_out == "Invalid date"
                                ? ""
                                : clockOut}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="my-btn slider-btn text-center">
                      <button
                        className="btn-property pdf btn-blue btn btn-primary"
                        id="empPdfData"
                        // href={empPdf}
                        // download
                        // rel="noreferrer"
                        // target="_blank"
                        onClick={exportEmpPdf}
                      >
                        Export PDF
                        <span className="corner">
                          <img
                            className="plus"
                            src={config.appBaseName + "images/white-plus.png"}
                            alt=""
                          />
                        </span>
                      </button>

                      <button
                        className="btn-property pdf  btn btn-primary"
                        id="printPdfData"
                        // href={empPdf}
                        // download
                        // rel="noreferrer"
                        // target="_blank"
                        onClick={exportEmpPdf}
                      >
                        Print
                        <span className="corner">
                          <img
                            className="plus"
                            src={config.appBaseName + "images/white-plus.png"}
                            alt=""
                          />
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
    </>
  );
};

export default Service;
