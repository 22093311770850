import React, { useState } from "react";
import config from "../common/config";
import $ from "jquery";
import { contactUs } from "../services/services";
import { NotificationManager } from "react-notifications";
import { useForm } from "react-hook-form";
const ContactUs = (props) => {
  const [userName, setUserName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [subject, setSubject] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const validation =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const handleSuccess = async (data) => {
    $("#loadSpin").removeAttr("hidden", "hidden");
    $(".edit-row ").css("opacity", ".1");
    $(".select").css("pointer-events", "none");

    if (!email.trim()) {
      NotificationManager.error("Please enter email");
      
    $("#loadSpin").attr("hidden", "hidden");
    $(".edit-row ").css("opacity", "1");
    $(".select").css("pointer-events", "fill");
      return;
      } else if (!email.trim() || validation.test(email) === false) {
        NotificationManager.error("Please enter Valid Email");
        
    $("#loadSpin").attr("hidden", "hidden");
    $(".edit-row ").css("opacity", "1");
    $(".select").css("pointer-events", "fill");
        return false;
    }

    if (!phone.trim) {
      $("#loadSpin").attr("hidden", "hidden");
      $(".select").css("pointer-events", "fill");
      $(".edit-row ").css("opacity", "1");
      NotificationManager.error("Enter Phone Number");
    } else if (!phone || phone.length > 10 || phone.length < 10) {
      $("#loadSpin").attr("hidden", "hidden");

      $(".select").css("pointer-events", "fill");
      $(".edit-row ").css("opacity", "1");
      NotificationManager.error("Please enter Valid Phone Number.");
      return false;
    }
    var formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });

    formData.append("name", userName);
    formData.append("phone", phone);
    formData.append("email", email);
    formData.append("message", message);
    formData.append("subject", subject);
    var apiResponse = await contactUs(formData);
    if (apiResponse.ResponseCode == 1 || apiResponse.ResponseCode == "1") {
      $("#loadSpin").attr("hidden", "hidden");
      $(".edit-row ").css("opacity", "1");
      $(".select").css("pointer-events", "fill");

      NotificationManager.success(apiResponse.ResponseMsg);
      localStorage.setItem("userMessage", JSON.stringify(apiResponse.data));
    } else {
      $("#loadSpin").attr("hidden", "hidden");
      $(".edit-row ").css("opacity", "1");
      $(".select").css("pointer-events", "fill");
      NotificationManager.error(apiResponse.ResponseMsg);
    }
  };
  return (
    <div id="contact" className="home contact-us contact-wrapper">
      {/* {props.Header} */}
      <div className="home-container">
        <div className="row ">
          {/* <div className="col-lg-6 col-12"></div> */}
          <div className="col-lg-6 col-12 mt-lg-0 contact-form">
            <div className="login-form edit-row">
              <h2>Get In Touch</h2>
              <form onSubmit={handleSubmit(handleSuccess)}>
                <input
                  type="text"
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                  placeholder="Enter Subject"
                  required
                />
                <input
                  type="text"
                  value={userName}
                  onChange={(e) => setUserName(e.target.value)}
                  placeholder="Enter Your Name"
                  required
                />
                <input
                  type="text"
                  value={phone}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  maxLength="10"
                  onChange={(e) => setPhone(e.target.value)}
                  placeholder="Enter Your Phone No."
                  required
                />
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter Your Email Address"
                  required
                />
                <textarea
                  name=""
                  id="message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  cols="30"
                  rows="7"
                  placeholder="Message"
                ></textarea>
                <button type="submit" className="select">
                  Send
                  <span>
                    <img
                      className="plus"
                      src={config.appBaseName + "images/white-plus.png"}
                      alt=""
                    />
                  </span>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
