import React, { useEffect, useState } from "react";
import Tab from "react-bootstrap/Tab";
import { Col, Nav, Row } from "react-bootstrap";
import config from "../common/config";
import $ from "jquery";
import { NotificationManager } from "react-notifications";
import { useForm } from "react-hook-form";
import { signUp, login, forgotPassword, serviceList } from "../services/services";
import moment from "moment";

const LoginForm = () => {
  const [number, setNumber] = useState("");
  const [user, setUser] = useState("");
  const [email, setEmail] = useState("");
  const [userType, setUserType] = useState("");
  const [password, setPassword] = useState("");
  const [validPass, setValidPass] = useState(false);  
  const [serviceData, setServiceData] = useState();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const isLogin = localStorage.getItem("isLogged");
  const userData = JSON.parse(localStorage.getItem("user"));
  const user_Type = localStorage.getItem("user_type");
  const isClockIn = JSON.parse(localStorage.getItem("isClockIn"));

  const validation =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const validPassword = new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
  );

  const ServiceList = async () => {
    var formData = new FormData();
    var apiResponse = await serviceList(formData);
    if (apiResponse.ResonseCode == 1 || apiResponse.ResponseCode == "1") {
      setServiceData(apiResponse.data);
    }
  };

  useEffect(() => {
    ServiceList();
  }, [])
  
  function togglePass() {
    var id = document.getElementById("password");
    if (id.type === "password") {
      id.type = "text";
      $(".i-pass").removeClass("fa-eye-slash");
      $(".i-pass").addClass("fa-eye");
    } else {
      id.type = "password";
      $(".i-pass").addClass("fa-eye-slash");
      $(".i-pass").removeClass("fa-eye");
    }
  }
  const date = new Date();

  // login
  const handleSuccess = async (data) => {
    $("#loadSpin").removeAttr("hidden", "hidden");
    $(".edit-row ").css("opacity", ".1");
    $(".select").css("pointer-events", "none");

    var formData = new FormData();
    var md5 = require("md5");

    Object.keys(data).forEach((key) => {
      if (key == "password") {
        var pval = md5(data[key]);
        formData.append(key, pval);
      } else {
        formData.append(key, data[key]);
      }
    });
    var apiResponse = await login(formData);
    if (apiResponse.ResponseCode == 1 || apiResponse.ResponseCode == "1") {
      $("#loadSpin").attr("hidden", "hidden");
      $(".edit-row ").css("opacity", "1");
      $(".select").css("pointer-events", "fill");

      NotificationManager.success(apiResponse.ResponseMsg);
      localStorage.setItem("user", JSON.stringify(apiResponse.data));
      localStorage.setItem("isLogged", true);
      localStorage.setItem("user_type", user_Type);
      localStorage.setItem( "isClockIn", JSON.stringify({ is_clock_out: apiResponse.data.is_clock_out, is_clock_in: apiResponse.data.is_clock_in, }) );
      window.location.reload();
    } else {
      $("#loadSpin").attr("hidden", "hidden");
      $(".edit-row ").css("opacity", "1");
      $(".select").css("pointer-events", "fill");
      NotificationManager.error(apiResponse.ResponseMsg);
    }
  };

  // signUp
  const submit = async (data) => {
    data.preventDefault();
    $("#loadSpin").removeAttr("hidden", "hidden");
    $(".edit-row ").css("opacity", ".1");
    $(".select").css("pointer-events", "none");
    // data.preventDefault();
    if (!email.trim()) {
      $("#loadSpin").attr("hidden", "hidden");

      $(".select").css("pointer-events", "fill");
      $(".edit-row ").css("opacity", "1");
      NotificationManager.error("Please Enter Email");
    } else if (!email.trim || validation.test(email) === false) {
      $("#loadSpin").attr("hidden", "hidden");
      $(".select").css("pointer-events", "fill");
      $(".edit-row ").css("opacity", "1");
      NotificationManager.error("Please Enter Valid Email");
      return false;
    }

    if (!number.trim) {
      $("#loadSpin").attr("hidden", "hidden");
      $(".select").css("pointer-events", "fill");
      $(".edit-row ").css("opacity", "1");
      NotificationManager.error("Enter Phone Number");
    } else if (!number || number.length > 10 || number.length < 10) {
      $("#loadSpin").attr("hidden", "hidden");

      $(".select").css("pointer-events", "fill");
      $(".edit-row ").css("opacity", "1");
      NotificationManager.error("Please enter Valid Phone Number.");
      return false;
    }

    if (!password.trim) {
      $("#loadSpin").attr("hidden", "hidden");
      $(".select").css("pointer-events", "fill");
      $(".edit-row ").css("opacity", "1");
      NotificationManager.error("Enter Password");
    } else if (!password.trim() || validPassword.test(password) === false) {
      $("#loadSpin").attr("hidden", "hidden");
      $(".select").css("pointer-events", "fill");
      $(".edit-row ").css("opacity", "1");
      setValidPass(true);
      return false;
    }

    var formData = new FormData();

    var md5 = require("md5");
    formData.append("email", email);
    formData.append("fullname", user);
    formData.append("user_type", userType);
    formData.append("password", md5(password));
    formData.append("phone", number);

    var apiResponse = await signUp(formData);

    if (apiResponse.ResponseCode == 0 || apiResponse.ResponseCode == "0") {
      $("#loadSpin").attr("hidden", "hidden");
      $(".edit-row ").css("opacity", "1");
      $(".select").css("pointer-events", "fill");
      NotificationManager.error(apiResponse.ResponseMsg);
    } else if (
      apiResponse.ResponseCode == 1 ||
      apiResponse.ResponseCode == "1"
    ) {
      $("#loadSpin").attr("hidden", "hidden");
      $(".edit-row ").css("opacity", "1");
      $(".select").css("pointer-events", "fill");
      NotificationManager.success(apiResponse.ResponseMsg);
      // localStorage.setItem("user", JSON.stringify(apiResponse.data)
      // );
      localStorage.setItem("isLogged", false);
      // window.location.reload();
      // localStorage.setItem("user_type", apiResponse.data.user_type);
      $(".login-form").trigger("reset");
    } else {
      $("#loadSpin").attr("hidden", "hidden");
      $(".edit-row ").css("opacity", "1");
      $(".select").css("pointer-events", "fill");
      NotificationManager.error(apiResponse.ResponseMsg);
    }
  };

  const [resetPass, setResetPass] = useState(false);
  const resetPassword = () => {
    setResetPass(true);
  };

  // forgotPassword
  const handleResetPass = async (data) => {
    $("#loadSpin").removeAttr("hidden", "hidden");
    $(".edit-row ").css("opacity", ".1");
    $(".select").css("pointer-events", "none");

    var formData = new FormData();

    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });

    var apiResponse = await forgotPassword(formData);
    if (apiResponse.ResponseCode == 1 || apiResponse.ResponseCode == "1") {
      $("#loadSpin").attr("hidden", "hidden");
      $(".edit-row ").css("opacity", "1");
      $(".select").css("pointer-events", "fill");

      NotificationManager.success(apiResponse.ResponseMsg);
    } else {
      $("#loadSpin").attr("hidden", "hidden");
      $(".edit-row ").css("opacity", "1");
      $(".select").css("pointer-events", "fill");
      NotificationManager.error(apiResponse.ResponseMsg);
    }
  };

  const back = () => {
    setResetPass(false);
  };
  return (
    <>
      {isLogin &&
      (isLogin == true || isLogin == "true") &&
      (userData.user_type == "Manager" || userData.user_type == "Property Manager") ? (
        ""
      ) : isLogin &&
        (isLogin == true || isLogin == "true") &&
        (userData.user_type !== "Manager" || userData.user_type !== "Property Manager") ? (
        <Tab.Container id="logout-tabs-example" defaultActiveKey="login">
          <div id="loadSpin" className="spinner " role="status" hidden>
            <span className="spinner-border"></span>
          </div>
          {isClockIn && isClockIn.is_clock_in == isClockIn.is_clock_out ? (
            <a href="#clock" className="select">
              Clock In
              <span>
                <img
                  className="plus"
                  src={config.appBaseName + "images/plus.png"}
                  alt=""
                />
              </span>
            </a>
          ) : (
            <a href="#clock" className="select not-allowed">
              Clock In
              <span>
                <img
                  className="plus"
                  src={config.appBaseName + "images/plus.png"}
                  alt=""
                />
              </span>
            </a>
          )}

          {isClockIn && isClockIn.is_clock_in !== isClockIn.is_clock_out ? (
            <>
              <a href="#clock" className="select">
                Clock Out
                <span>
                  <img
                    className="plus"
                    src={config.appBaseName + "images/plus.png"}
                    alt=""
                  />
                </span>
              </a>
              <a href="#clock" className="select">
                Upload Photos
                <span>
                  <img
                    className="plus"
                    src={config.appBaseName + "images/plus.png"}
                    alt=""
                  />
                </span>
              </a>
            </>
          ) : (
            <a href="#clock" className="select not-allowed">
              Clock Out
              <span>
                <img
                  className="plus"
                  src={config.appBaseName + "images/plus.png"}
                  alt=""
                />
              </span>
            </a>
          )}
        </Tab.Container>
      ) : resetPass === false || resetPass === "false" ? (
        <Tab.Container id="left-tabs-example" defaultActiveKey="login">
          <div id="loadSpin" className="spinner " role="status" hidden>
            <span className="spinner-border"></span>
          </div>
          <div className="edit-row">
            <div className="tab-pils">
              <Nav variant="pills" className="flex-column edit-column">
                <Nav.Item>
                  <Nav.Link eventKey="login">Login</Nav.Link>
                </Nav.Item>
              </Nav>
              <Nav variant="pills" className="flex-column">
                <Nav.Item>
                  <Nav.Link eventKey="register">Register</Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
            <Tab.Content>
              <Tab.Pane eventKey="login">
                <div className="login-form">
                  <form onSubmit={handleSubmit(handleSuccess)}>
                    <input
                      type="email"
                      autoComplete="off"
                      name="email"
                      placeholder="Enter Your Email"
                      {...register("email", {
                        required: "This is required.",
                      })}
                    />
                    <input
                      className="mb-2"
                      type="password"
                      name="password"
                      autoComplete="off"
                      placeholder="Enter Password"
                      {...register("password", {
                        required: "This is required.",
                        maxLength: 80,
                      })}
                    />
                    <p className="text-end" onClick={resetPassword}>
                      <u>Forgot Password?</u>
                    </p>
                    <button type="text" className="select">
                      Login
                      <span>
                        <img
                          className="plus"
                          src={config.appBaseName + "images/white-plus.png"}
                          alt=""
                        />
                      </span>
                    </button>
                  </form>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="register">
                <div className="login-form">
                  <form onSubmit={submit}>
                    <input
                      type="email"
                      name="email"
                      placeholder="Enter Your Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                    <input
                      type="text"
                      name="fullname"
                      placeholder="Enter Full Name"
                      value={user}
                      onChange={(e) => setUser(e.target.value)}
                      required
                    />
                    <input
                      type="text"
                      name="phone"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      maxLength="10"
                      placeholder="Enter Your Phone No."
                      onChange={(e) => setNumber(e.target.value)}
                      value={number}
                      required
                    />

                    <div className="hide-show-pass">
                      <input
                        id="password"
                        type="password"
                        placeholder="Create Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                      />
                      <i
                        className="i-pass fa fa-eye-slash"
                        onClick={togglePass}
                      ></i>
                      {(validPass === "true" || validPass === true) && (
                        <>
                          <ul className="password-validate">
                            <li>Minimum 8 characters</li>
                            <li>At least 1 upper letter </li>
                            <li>At least 1 lower letter </li>
                            <li>At least 1 number</li>
                            <li>At least 1 special character </li>
                          </ul>
                        </>
                      )}
                    </div>
                    <select
                      name="user_type"
                      value={userType}
                      style={{ color: "#406d83" }}
                      onChange={(e) => setUserType(e.target.value)}
                      id="type"
                      className="select-icon"
                      required
                    >
                      <option selected value="" disabled>
                        Select User Type
                      </option>
                      <option value="Manager">Manager</option>
                      <option value="Property Manager">Property Manager</option>
                      <option value="Employee">Employee</option>
                    </select>
                    
                    <button type="submit" className="select">
                      Register
                      <span>
                        <img
                          className="plus"
                          src={config.appBaseName + "images/white-plus.png"}
                          alt=""
                        />
                      </span>
                    </button>
                  </form>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </div>
        </Tab.Container>
      ) : (
        <Tab.Container id="left-tabs-example" defaultActiveKey="login">
          <div id="loadSpin" className="spinner " role="status" hidden>
            <span className="spinner-border"></span>
          </div>
          <div className="edit-row">
            <div className="tab-pils">
              <Nav variant="pills" className="flex-column edit-column">
                <Nav.Item>
                  <Nav.Link className="active" eventKey="reset">
                    Forgot Password
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <i
                class="fa fa-chevron-left back-arrow"
                aria-hidden="true"
                onClick={back}
              ></i>
            </div>
            <Tab.Content>
              <Tab.Pane eventKey="reset" className="active show">
                <div className="login-form">
                  <form onSubmit={handleSubmit(handleResetPass)}>
                    <input
                      className="mb-4"
                      type="email"
                      name="email"
                      autoComplete="off"
                      placeholder="Enter Your email"
                      {...register("email", {
                        required: "This is required.",
                      })}
                    />
                    {/* 
                    <input
                      type="text"
                      autoComplete="off"
                      name="fullname"
                      placeholder="Confirm Your Password"
                      {...register("confirm_pass", {
                        required: "This is required.",
                        maxLength: 80,
                      })}
                    /> */}

                    <button type="text" className="select">
                      Submit
                      <span>
                        <img
                          className="plus"
                          src={config.appBaseName + "images/white-plus.png"}
                          alt=""
                        />
                      </span>
                    </button>
                  </form>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </div>
        </Tab.Container>
      )}
    </>
  );
};

export default LoginForm;
