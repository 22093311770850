import React, { useEffect } from "react";
import ClockCalender from "../components/ClockCalender";
import ContactUs from "../components/ContactUs";
import Exceptional from "../components/Exceptional";
import Profile from "../components/Profile";
import ServiceDatePicker from "../components/ServiceDatePicker";
import "react-notifications/lib/notifications.css";
import Service from "../components/Managerservice/Service";
import PropertService from "../components/PropertyManager/Service"
const Home = () => {
  const isLogin = localStorage.getItem("isLogged");
  const user = JSON.parse(localStorage.getItem("user"));
  return (
    <>
      <Exceptional />
      {isLogin === "false" ||
      isLogin === false ||
      isLogin === null ||
      isLogin === "" ? (
        ""
      ) : user && user.user_type === "Manager" ? (
        <>
          <Profile />
          {/* <Service /> */}
          <Service />
          {/* <ServiceDatePicker /> */}
        </>
      ) : user && user.user_type === "Property Manager" ? (
        <>
          <Profile />
          <PropertService />
        </>
      ) : (
        <>
          <Profile />
          <ClockCalender />
        </>
      )}
      <ContactUs />
      {/* <!-- Button trigger modal --> */}
    </>
  );
};

export default Home;
