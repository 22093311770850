import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { Row, Col, Button } from "react-bootstrap";
import config from "../common/config";
import { useForm } from "react-hook-form";
import $ from "jquery";
import {
  updateProfile,
  serviceList,
  createLocation,
  getLocation,
  deleteLocation,
} from "../services/services";
import { NotificationManager } from "react-notifications";
import md5 from "md5";
import ReactModal from "react-modal";
const Profile = () => {
  const isLogin = localStorage.getItem("isLogged");
  const user = JSON.parse(localStorage.getItem("user"));
  const userProfile = JSON.parse(localStorage.getItem("userProfile"));

  const [employeeName, setEmployeeName] = useState("");
  const [employeeNumber, setEmployeeNumber] = useState("");
  const [employeeService, setEmployeeService] = useState("");
  const [managerName, setmanagerName] = useState("");
  const [propertyName, setPropertyName] = useState("");
  const [employeeCity, setEmployeeCity] = useState("");
  const [employeeCountry, setEmployeeCountry] = useState("");

  const [trackerData, setTrackerData] = useState(false);
  const [trackerId, setTrackerId] = useState("");
  const [createTrackName, setCreateTrackName] = useState("");
  const [trackLIst, setTrackList] = useState("");

  const [formProfileData, updateFormProfileData] = useState("");
  const [serviceData, setServiceData] = useState();
  const [managerList, setManagerList] = useState("");
  const [checkedServices, setCheckedServices] = useState([]);
  const [preview, setPreview] = useState({
    picture: false,
    src: false,
  });
  const fileHandler = (e) => {
    updateFormProfileData({
      ...formProfileData,
      [e.target.name]: e.target.files[0],
    });
    var picture = e.target.files[0];
    var src = URL.createObjectURL(picture);
    setPreview({ picture: picture, src: src });
  };

  const validPassword = new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
  );

  // propertyOutModal
  function openTrackerModal(data) {
    setTrackerData(true);
    setTrackerId(data);
    localStorage.setItem("trackId", data);
    console.log("openTrackerModal", data);
    console.log("setTrackerId", trackerId);
  }
  function closeTrackerModal() {
    setTrackerData(false);
  }

  const handleCheckbox = (event) => {
    const target = event.target;
    let value = target.value;
    let name = target.name;
    if (target.checked) {
      setCheckedServices((checkedServices) => [...checkedServices, value]);
    } else {
      setCheckedServices(checkedServices.filter((item) => item !== value));
    }
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  // const manager = "Manager Name"
  const editProfile = async (data) => {
    $("#loadProfile").removeAttr("hidden", "hidden");
    $(".employee-profile").css("opacity", ".1");
    $(".btn-blue").css("pointer-events", "none");
    // if(password.length > 0 && validPassword.test(password) === false){
    //   NotificationManager.error(
    //     "Please enter a Valid Password that contains 1 lowercase, Uppercase, Number, Symbol, and password length must be more than 8 "
    //   );
    // }
    if (!employeeNumber.trim) {
      $("#loadProfile").attr("hidden", "hidden");
      $(".employee-profile").css("opacity", "1");
      $(".btn-blue").css("pointer-events", "fill");
      NotificationManager.error("Enter Phone Number");
    } else if (
      !employeeNumber ||
      employeeNumber.length > 10 ||
      employeeNumber.length < 10
    ) {
      $("#loadProfile").attr("hidden", "hidden");
      $(".employee-profile").css("opacity", "1");
      $(".btn-blue").css("pointer-events", "fill");
      NotificationManager.error("Please enter Valid Phone Number.");
      return false;
    }

    var formData = new FormData();

    formData.append("user_id", user.user_id);
    formData.append("fullname", employeeName);
    formData.append("property_name", propertyName);
    formData.append("phone", employeeNumber);
    formData.append("services", checkedServices);
    formData.append("manager_id", managerName);
    formData.append("city", employeeCity);
    formData.append("country", employeeCountry);
    // formData.append("manager_name", managerName);
    if (preview.picture === false) {
      formData.append("profile", formProfileData);
    } else {
      formData.append("profile", preview.picture);
    }
    var apiResponse = await updateProfile(formData);
    if (apiResponse.ResonseCode == 1 || apiResponse.ResponseCode == "1") {
      $("#loadProfile").attr("hidden", "hidden");
      $(".employee-profile").css("opacity", "1");
      $(".btn-blue").css("pointer-events", "fill");
      NotificationManager.success(apiResponse.ResponseMsg);
      localStorage.setItem("user", JSON.stringify(apiResponse.data));

      // localStorage.setItem("user", JSON.stringify(apiResponse.data));
      // window.location.reload();
    } else {
      $("#loadProfile").attr("hidden", "hidden");
      $(".employee-profile").css("opacity", "1");
      $(".btn-blue").css("pointer-events", "fill");
      NotificationManager.error(apiResponse.ResponseMsg);
    }
  };

  const updateProfileData = async () => {
    if (user && (user.fullname === null || user.fullname === "null")) {
      return "";
    } else {
      setEmployeeName(user && user.fullname);
    }

    if (user && (user.phone == null || user.phone == "null")) {
      return "";
    } else {
      setEmployeeNumber(user && user.phone);
    }

    setEmployeeService(user && user.services);
    // setmanagerName(manager);
    if (user && (user.city === null || user.city === "null")) {
      return "";
    } else {
      setEmployeeCity(user && user.city);
    }

    if (user && (user.country === null || user.country === "null")) {
      return "";
    } else {
      setEmployeeCountry(user && user.country);
    }

    if (
      user &&
      (user.property_name === null || user.property_name === "null")
    ) {
      return "";
    } else {
      setPropertyName(user && user.property_name);
    }
  };

  const ServiceList = async () => {
    var formData = new FormData();

    formData.append("user_id", user.user_id);
    var apiResponse = await serviceList(formData);
    if (apiResponse.ResonseCode == 1 || apiResponse.ResponseCode == "1") {
      setServiceData(apiResponse.data);
      for (let index = 0; index < apiResponse.data.length; index++) {
        const element = apiResponse.data[index];
        for (let j = 0; j < element.sub_service.length; j++) {
          const elementSub = element.sub_service[j];
          if (elementSub.is_selected == "1" || elementSub.is_selected == 1) {
            setCheckedServices((checkedServices) => [
              ...checkedServices,
              elementSub.sub_id,
            ]);
          }
        }
      }
    }
  };

  // createTrackerList
  const handleCreateTrack = async (data) => {
    data.preventDefault();
    $("#loadSpins").attr("hidden", "hidden");
    $(".hide-modal").css("opacity", ".1");
    $(".select").css("pointer-events", "none");
    var formData = new FormData();
    formData.append("user_id", user && user.user_id);
    formData.append("service_id", trackerId);
    formData.append("name", createTrackName);
    var apiResponse = await createLocation(formData);

    if (apiResponse.ResonseCode == 1 || apiResponse.ResponseCode == "1") {
      $("#loadSpins").attr("hidden", "hidden");
      $(".hide-modal ").css("opacity", "1");
      $(".select").css("pointer-events", "fill");
      console.log("apiresTrackCreate", apiResponse);
      NotificationManager.success(apiResponse.ResponseMsg);
      ServiceList();
      closeTrackerModal(true);
    } else {
      $("#loadSpins").attr("hidden", "hidden");
      $(".hide-modal ").css("opacity", "1");
      $(".select").css("pointer-events", "fill");
      NotificationManager.error(apiResponse.ResponseMsg);
    }
  };

  // getTracjKist
  const deleteTrackerList = async (data) => {
    var formData = new FormData();
    $("#loadProfile").removeAttr("hidden", "hidden");
    $(".employee-profile").css("opacity", ".1");
    $(".btn-blue").css("pointer-events", "none");
    formData.append("user_id", user && user.user_id);
    formData.append("id", data);
    var apiResponse = await deleteLocation(formData);

    if (apiResponse.ResonseCode == 1 || apiResponse.ResponseCode == "1") {
      $("#loadProfile").attr("hidden", "hidden");
      $(".employee-profile").css("opacity", "1");
      $(".btn-blue").css("pointer-events", "fill");
      ServiceList();
    }
  };

  useEffect(() => {
    ServiceList();
    updateProfileData();
    setManagerList(user && user.manager_list);
  }, []);

  const logInErr = () => {
    NotificationManager.error("Please Login First");
    $("#loadProfile").attr("hidden", "hidden");
    $(".signup-form ").css("opacity", "1");
    $(".save").css("pointer-events", "fill");
  };
  return (
    <>
      {/* <Header /> */}
      {isLogin === "false" ||
      isLogin === false ||
      isLogin === null ||
      isLogin === "" ? (
        ""
      ) : (
        <section id="profile" className="employee-profile">
          <div id="loadProfile" className="spinner" role="status" hidden>
            <span className="spinner-border"></span>
          </div>
          <div className="my-container">
            <div className="signup-form">
              <Form onSubmit={handleSubmit(editProfile)}>
                <div className="col-12 text-center profile-section">
                  <div className="profile-img img-upload">
                    <input
                      name="profile"
                      type="file"
                      accept="image/*"
                      className="image-file-y upload-file"
                      onChange={fileHandler}
                      // required
                    />
                    <div className="picture-preview-y">
                      {preview.src ? (
                        <img
                          className="set-show-preview"
                          src={preview.src}
                          alt={preview.src}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                    {user && user.profile && user.profile != "" ? (
                      <img
                        src={user.profile}
                        className="img-fluid d-block "
                        alt=""
                      />
                    ) : (
                      <img
                        src={config.appBaseName + "images/profile.png"}
                        className="img-fluid d-block "
                        alt=""
                      />
                    )}
                  </div>
                  <div className="employee-name">
                    <h3>
                      {user && user.fullname != null ? user.fullname : "User"}
                    </h3>
                  </div>
                </div>
                <div className="col-12 text-center">
                  <Row className="mb-3">
                    {user.user_type === "Manager" ||
                    user.user_type === "Property Manager" ? (
                      <Form.Group
                        className="myInputRow col-12 col-sm-6 col-lg-4"
                        controlId="fullname"
                      >
                        <Form.Control
                          type="text"
                          name="fullname"
                          onChange={(e) => setEmployeeName(e.target.value)}
                          value={employeeName}
                          placeholder={"Manager Name"}
                        />
                        <span className="corner">
                          <img
                            className="plus"
                            src={config.appBaseName + "images/color-bg.png"}
                            alt=""
                          />
                        </span>
                      </Form.Group>
                    ) : (
                      <Form.Group
                        className="myInputRow col-12 col-sm-6 col-lg-4"
                        controlId="fullname"
                      >
                        <Form.Control
                          type="text"
                          name="fullname"
                          onChange={(e) => setEmployeeName(e.target.value)}
                          value={employeeName}
                          placeholder={"Employee Name"}
                        />
                        <span className="corner">
                          <img
                            className="plus"
                            src={config.appBaseName + "images/color-bg.png"}
                            alt=""
                          />
                        </span>
                      </Form.Group>
                    )}

                    <Form.Group
                      className="myInputRow col-12 col-sm-6 col-lg-4"
                      controlId="phone"
                    >
                      <Form.Control
                        type="text"
                        name="phone"
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        maxLength="10"
                        onChange={(e) => setEmployeeNumber(e.target.value)}
                        value={employeeNumber}
                        placeholder="Phone No."
                      />
                      <span className="corner">
                        <img
                          className="plus"
                          src={config.appBaseName + "images/color-bg.png"}
                          alt=""
                        />
                      </span>
                    </Form.Group>

                    {/* <Form.Group
                      className="myInputRow col-12 col-sm-6 col-lg-4"
                      controlId="Services"
                    >
                      <Form.Select
                        placeholder="Employee Services"
                        onChange={(e) => setEmployeeService(e.target.value)}
                        value={employeeService}
                        // defaultValue="Employee Services"
                      >
                        <option value="" disabled>
                          {" "}
                          Employee Services
                        </option>
                        {serviceData &&
                          serviceData.map((e, key) => {
                            return (
                              <option key={key} value={e.service}>
                                {e.service}
                              </option>
                            );
                          })}
                      </Form.Select>
                      <span className="corner">
                        <img
                          className="plus"
                          src={config.appBaseName + "images/color-bg.png"}
                          alt=""
                        />
                      </span>
                    </Form.Group> */}

                    {user.user_type === "Manager" ||
                    user.user_type === "Property Manager" ? (
                      ""
                    ) : (
                      <Form.Group
                        className="myInputRow col-12 col-sm-6 col-lg-4"
                        controlId="manager_id"
                      >
                        <Form.Select
                          placeholder="Manager Name"
                          onChange={(e) => setmanagerName(e.target.value)}
                          value={managerName}
                          // defaultValue="Employee Services"
                        >
                          <option value="" disabled>
                            Manager Name
                          </option>
                          {managerList &&
                            managerList.map((e, key) => {
                              return (
                                <option key={key} value={e.user_id}>
                                  {e.fullname}
                                </option>
                              );
                            })}
                        </Form.Select>
                        <span className="corner">
                          <img
                            className="plus"
                            src={config.appBaseName + "images/color-bg.png"}
                            alt=""
                          />
                        </span>
                      </Form.Group>
                    )}

                    {user.user_type === "Property Manager" ? (
                      <Form.Group
                        className="myInputRow col-12 col-sm-6 col-lg-4"
                        controlId="propertyname"
                      >
                        <Form.Control
                          type="text"
                          name="property_name"
                          onChange={(e) => setPropertyName(e.target.value)}
                          value={propertyName}
                          placeholder="Property Name"
                        />
                        <span className="corner">
                          <img
                            className="plus"
                            src={config.appBaseName + "images/color-bg.png"}
                            alt=""
                          />
                        </span>
                      </Form.Group>
                    ) : (
                      ""
                    )}

                    {user.user_type === "Manager" ||
                    user.user_type === "Property Manager" ? (
                      <>
                        <Form.Group
                          className="myInputRow col-12 col-sm-6 col-lg-4"
                          controlId="city"
                        >
                          <Form.Control
                            type="text"
                            name="city"
                            onChange={(e) => setEmployeeCity(e.target.value)}
                            value={employeeCity}
                            placeholder="City"
                          />
                          <span className="corner">
                            <img
                              className="plus"
                              src={config.appBaseName + "images/color-bg.png"}
                              alt=""
                            />
                          </span>
                        </Form.Group>

                        <Form.Group
                          className="myInputRow col-12 col-sm-6 col-lg-4"
                          controlId="country"
                        >
                          <Form.Control
                            type="text"
                            name="country"
                            onChange={(e) => setEmployeeCountry(e.target.value)}
                            value={employeeCountry}
                            placeholder="Country"
                          />
                          <span className="corner">
                            <img
                              className="plus"
                              src={config.appBaseName + "images/color-bg.png"}
                              alt=""
                            />
                          </span>
                        </Form.Group>
                      </>
                    ) : (
                      <>
                        {/* <Form.Group
                          className="myInputRow col-12 col-sm-6 col-lg-4"
                          controlId="city"
                        >
                          <Form.Control
                            type="text"
                            name="city"
                            onChange={(e) => setEmployeeCity(e.target.value)}
                            value={employeeCity}
                            placeholder="Employee City"
                          />
                          <span className="corner">
                            <img
                              className="plus"
                              src={config.appBaseName + "images/color-bg.png"}
                              alt=""
                            />
                          </span>
                        </Form.Group>

                        <Form.Group
                          className="myInputRow col-12 col-sm-6 col-lg-4"
                          controlId="country"
                        >
                          <Form.Control
                            type="text"
                            name="country"
                            onChange={(e) => setEmployeeCountry(e.target.value)}
                            value={employeeCountry}
                            placeholder="Employee Country"
                          />
                          <span className="corner">
                            <img
                              className="plus"
                              src={config.appBaseName + "images/color-bg.png"}
                              alt=""
                            />
                          </span>
                        </Form.Group> */}
                      </>
                    )}
                    {/* <Form.Group
                      className="myInputRow col-12 col-sm-6 col-lg-4"
                      controlId="reset"
                    >
                      <Form.Control
                        type="text"
                        name="reset"
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}
                        placeholder="Change Password"
                      />
                      <span className="corner">
                        <img
                          className="plus"
                          src={config.appBaseName + "images/color-bg.png"}
                          alt=""
                        />
                      </span>
                    </Form.Group> */}
                    {user.user_type === "Manager" ? (
                      <div className="services-check d-flex flex-column justify-content-center flex-wrap">
                        <h3>Services</h3>
                        <div className="row">
                          {serviceData &&
                            serviceData.map((list, i) => (
                              <div className="d-flex justify-content-start col-lg-2 col-md-3 col-6">
                                <Form.Group
                                  className="mb-3"
                                  controlId="formBasicCheckbox"
                                  key={list.service_id}
                                >
                                  <Form.Label>{list.service}</Form.Label>
                                  {list.sub_service.length > 0 &&
                                    list.sub_service.map((sublist, i) => {
                                      return (
                                        <>
                                          <div className="d-flex">
                                            <Form.Check
                                              type="checkbox"
                                              name="checkService"
                                              defaultChecked={
                                                sublist.is_selected == "1" ||
                                                sublist.is_selected == 1
                                                  ? "checked"
                                                  : ""
                                              }
                                              key={sublist.sub_id}
                                              value={sublist.sub_id}
                                              onChange={handleCheckbox}
                                              label={sublist.name}
                                            />
                                            {sublist.name === "Tracker" ? (
                                              <button
                                                type="button"
                                                id={sublist.sub_id}
                                                className="tracker-btn"
                                                onClick={() =>
                                                  openTrackerModal(
                                                    sublist.sub_id
                                                  )
                                                }
                                              >
                                                +
                                              </button>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                          {sublist.name === "Tracker" ? (
                                            <>
                                              {sublist &&
                                                sublist.property.map(
                                                  (data, i) => {
                                                    return (
                                                      <div
                                                        className="tracker-list"
                                                        key={i}
                                                      >
                                                        <p>{data.name}</p>
                                                        <button
                                                          type="button"
                                                          className="tracker-btn"
                                                          onClick={() =>
                                                            deleteTrackerList(
                                                              data.id
                                                            )
                                                          }
                                                        >
                                                          ×
                                                        </button>
                                                      </div>
                                                    );
                                                  }
                                                )}
                                            </>
                                          ) : (
                                            ""
                                          )}
                                        </>
                                      );
                                    })}
                                </Form.Group>
                              </div>
                            ))}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </Row>

                  <div className="my-btn">
                    {/* {isLogin && (isLogin === "true" || isLogin === true) ? (
                      <Button
                        className="btn-property btn-blue save"
                        type="submit"
                      >
                        Save
                        <span className="corner">
                          <img
                            className="plus"
                            src={config.appBaseName + "images/white-plus.png"}
                            alt=""
                          />
                        </span>
                      </Button>
                    ) : (
                      <Button
                        className="btn-property btn-blue save"
                        type="submit"
                        onClick={logInErr}
                      >
                        Save
                        <span className="corner">
                          <img
                            className="plus"
                            src={config.appBaseName + "images/white-plus.png"}
                            alt=""
                          />
                        </span>
                      </Button>
                    )} */}
                    {isLogin && (isLogin === "true" || isLogin === true) ? (
                      <Button className="btn-property btn-blue" type="submit">
                        Update Profile
                        <span className="corner">
                          <img
                            className="plus"
                            src={config.appBaseName + "images/white-plus.png"}
                            alt=""
                          />
                        </span>
                      </Button>
                    ) : (
                      <Button
                        className="btn-property btn-blue "
                        type="submit"
                        onClick={logInErr}
                      >
                        Update Profile
                        <span className="corner">
                          <img
                            className="plus"
                            src={config.appBaseName + "images/white-plus.png"}
                            alt=""
                          />
                        </span>
                      </Button>
                    )}
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </section>
      )}

      {/* trackerModal */}
      <div className="map-modal">
        <ReactModal
          ariaHideApp={false}
          isOpen={trackerData}
          contentLabel="Example Modal"
          id="trackerModal"
        >
          <div id="loadSpins" className="spinner " role="status" hidden>
            <span className="spinner-border"></span>
          </div>
          <img
            src={config.appBaseName + "images/close-btn.png"}
            onClick={closeTrackerModal}
            className="modal-close"
            alt=""
          />
          <div className="map-modal upload-modal hide-modal">
            <div className="modal-form login-form ">
              <form onSubmit={handleCreateTrack}>
                <input
                  type="text"
                  placeholder="Enter Tracker"
                  value={createTrackName}
                  onChange={(e) => setCreateTrackName(e.target.value)}
                />
                <button type="submit" className="select orange-bg">
                  Add
                  <span>
                    <img
                      className="plus"
                      src={config.appBaseName + "images/white-plus.png"}
                      alt=""
                    />
                  </span>
                </button>
              </form>
            </div>
          </div>
        </ReactModal>
      </div>
    </>
  );
};

export default Profile;
