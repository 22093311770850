import axios from "axios";
import { NotificationManager } from "react-notifications";
import { toast } from "react-toastify";
import config from "../common/config";

// SignUp
const signUp = async (data) => {
  var apiPromise = new Promise((resolve, reject) => {
    axios
      .post(`${config.apiUrl}/SignUp`, data, {
        headers: {
          key: "2b223e5cee713615ha54ac203b24e9a123703mk411",
        },
      })
      .then(async (response) => {
        if (
          response.data.ResponseCode == 1 ||
          response.data.ResponseCode == "1"
        ) {
          return resolve(response.data);
        } else {
          return resolve(response.data);
        }
      })
      .catch((error) => {
        NotificationManager.error(error);
        return reject(error);
      });
  });
  return await apiPromise;
};

// logIn
const login = async (data) => {
  var apiPromise = new Promise((resolve, reject) => {
    axios
      .post(`${config.apiUrl}/login`, data, {
        headers: {
          key: "2b223e5cee713615ha54ac203b24e9a123703mk411",
        },
      })
      .then(async (response) => {
        if (
          response.data.ResponseCode == 1 ||
          response.data.ResponseCode == "1"
        ) {
          return resolve(response.data);
        } else {
          return resolve(response.data);
        }
      })
      .catch((error) => {
        return reject(error);
      });
  });
  return await apiPromise;
};

const forgotPassword = async (data) => {
  var apiPromise = new Promise((resolve, reject) => {
    axios
      .post(`${config.apiUrl}/forgotPassword`, data, {
        headers: {
          key: "2b223e5cee713615ha54ac203b24e9a123703mk411",
        },
      })
      .then(async (response) => {
        if (
          response.data.ResponseCode == 1 ||
          response.data.ResponseCode == "1"
        ) {
          return resolve(response.data);
        } else {
          return resolve(response.data);
        }
      })
      .catch((error) => {
        return reject(error);
      });
  });
  return await apiPromise;
};

const changePassword = async (data) => {
  const getToken = JSON.parse(localStorage.getItem("user"));

  var apiPromise = new Promise((resolve, reject) => {
    axios
      .post(`${config.apiUrl}/changePassword`, data, {
        headers: {
          key: "2b223e5cee713615ha54ac203b24e9a123703mk411",
          token:getToken.token
        },
      })
      .then(async (response) => {
        if (
          response.data.ResponseCode == 1 ||
          response.data.ResponseCode == "1"
        ) {
          return resolve(response.data);
        } else {
          return resolve(response.data);
        }
      })
      .catch((error) => {
        return reject(error);
      });
  });
  return await apiPromise;
};

const updateProfile = async (data) => {
  const getToken = JSON.parse(localStorage.getItem("user"));

  var apiPromise = new Promise((resolve, reject) => {
    axios
      .post(`${config.apiUrl}/updateProfile`, data, {
        headers: {
          key: "2b223e5cee713615ha54ac203b24e9a123703mk411",
          token:getToken.token
        },
      })
      .then(async (response) => {
        if (
          response.data.ResponseCode == 1 ||
          response.data.ResponseCode == "1"
        ) {
          return resolve(response.data);
        } else {
          return resolve(response.data);
        }
      })
      .catch((error) => {
        return reject(error);
      });
  });
  return await apiPromise;
};

const clockInclockOut = async (data) => {
  const getToken = JSON.parse(localStorage.getItem("user"));
  var apiPromise = new Promise((resolve, reject) => {
    axios
      .post(`${config.apiUrl}/clockInclockOut`, data, {
        headers: {
          key: "2b223e5cee713615ha54ac203b24e9a123703mk411",
          token: getToken.token
        },
      })
      .then(async (response) => {
        if (
          response.data.ResponseCode == 1 ||
          response.data.ResponseCode == "1"
        ) {
          return resolve(response.data);
        } else {
          return resolve(response.data);
        }
      })
      .catch((error) => {
        return reject(error);
      });
  });
  return await apiPromise;
};

const servicesImages = async (data) => {
  const getToken = JSON.parse(localStorage.getItem("user"));
  var apiPromise = new Promise((resolve, reject) => {
    axios
      .post(`${config.apiUrl}/servicesImages`, data, {
        headers: {
          key: "2b223e5cee713615ha54ac203b24e9a123703mk411",
          token: getToken.token
        },
      })
      .then(async (response) => {
        if (
          response.data.ResponseCode == 1 ||
          response.data.ResponseCode == "1"
        ) {
          return resolve(response.data);
        } else {
          return resolve(response.data);
        }
      })
      .catch((error) => {
        return reject(error);
      });
  });
  return await apiPromise;
};

const workImage = async (data) => {
  const getToken = JSON.parse(localStorage.getItem("user"));
  var apiPromise = new Promise((resolve, reject) => {
    axios
      .post(`${config.apiUrl}/workImage`, data, {
        headers: {
          key: "2b223e5cee713615ha54ac203b24e9a123703mk411",
          token: getToken.token,
        },
      })
      .then(async (response) => {
        if (
          response.data.ResponseCode == 1 ||
          response.data.ResponseCode == "1"
        ) {
          return resolve(response.data);
        } else {
          return resolve(response.data);
        }
      })
      .catch((error) => {
        return reject(error);
      });
  });
  return await apiPromise;
};

const queryImage = async (data) => {
  const getToken = JSON.parse(localStorage.getItem("user"));
  var apiPromise = new Promise((resolve, reject) => {
    axios
      .post(`${config.apiUrl}/queryImage`, data, {
        headers: {
          key: "2b223e5cee713615ha54ac203b24e9a123703mk411",
          token: getToken.token,
        },
      })
      .then(async (response) => {
        if (
          response.data.ResponseCode == 1 ||
          response.data.ResponseCode == "1"
        ) {
          return resolve(response.data);
        } else {
          return resolve(response.data);
        }
      })
      .catch((error) => {
        return reject(error);
      });
  });
  return await apiPromise;
};

const serviceList = async (data) => {
  const getToken = JSON.parse(localStorage.getItem("user"));

  var apiPromise = new Promise((resolve, reject) => {
    axios
      .post(`${config.apiUrl}/serviceList`, data, {
        headers: {
          key: "2b223e5cee713615ha54ac203b24e9a123703mk411",
          token: getToken && getToken.token,
        },
      })
      .then(async (response) => {
        if (
          response.data.ResponseCode == 1 ||
          response.data.ResponseCode == "1"
        ) {
          return resolve(response.data);
        } else {
          return resolve(response.data);
        }
      })
      .catch((error) => {
        NotificationManager.error(error);
        return reject(error);
      });
  });
  return await apiPromise;
};
const getPropertyList = async (data) => {
  const getToken = JSON.parse(localStorage.getItem("user"));

  var apiPromise = new Promise((resolve, reject) => {
    axios
      .post(`${config.apiUrl}/getPropertyList`, data, {
        headers: {
          key: "2b223e5cee713615ha54ac203b24e9a123703mk411",
          token: getToken && getToken.token,
        },
      })
      .then(async (response) => {
        if (
          response.data.ResponseCode == 1 ||
          response.data.ResponseCode == "1"
        ) {
          return resolve(response.data);
        } else {
          return resolve(response.data);
        }
      })
      .catch((error) => {
        NotificationManager.error(error);
        return reject(error);
      });
  });
  return await apiPromise;
};

const getEmployeeDetail = async (data) => {
  const getToken = JSON.parse(localStorage.getItem("user"));

  var apiPromise = new Promise((resolve, reject) => {
    axios
      .post(`${config.apiUrl}/getEmployeeDetail`, data, {
        headers: {
          key: "2b223e5cee713615ha54ac203b24e9a123703mk411",
          token:getToken.token,
        },
      })
      .then(async (response) => {
        if (
          response.data.ResponseCode == 1 ||
          response.data.ResponseCode == "1"
        ) {
          return resolve(response.data);
        } else {
          return resolve(response.data);
        }
      })
      .catch((error) => {
        NotificationManager.error(error);
        return reject(error);
      });
  });
  return await apiPromise;
};

const clockInclockOutUserList = async (data) => {
  const getToken = JSON.parse(localStorage.getItem("user"));

  var apiPromise = new Promise((resolve, reject) => {
    axios
      .post(`${config.apiUrl}/clockInclockOutUserList`, data, {
        headers: {
          key: "2b223e5cee713615ha54ac203b24e9a123703mk411",
          token: getToken.token,
        },
      })
      .then(async (response) => {
        if (
          response.data.ResponseCode == 1 ||
          response.data.ResponseCode == "1"
        ) {
          return resolve(response.data);
        } else {
          return resolve(response.data);
        }
      })
      .catch((error) => {
        NotificationManager.error(error);
        return reject(error);
      });
  });
  return await apiPromise;
};

const LogOut = async (data) => {
  const getToken = JSON.parse(localStorage.getItem("user"));
console.log("gettoken",getToken);
  var apiPromise = new Promise((resolve, reject) => {
    axios
      .post(`${config.apiUrl}/logOut`, data, {
        headers: {
          key: "2b223e5cee713615ha54ac203b24e9a123703mk411",
          token: getToken.token,
        },
      })
      .then(async (response) => {
        if (
          response.data.ResponseCode == 1 ||
          response.data.ResponseCode == "1"
        ) {
          return resolve(response.data);
        } else {
          return resolve(response.data);
        }
      })
      .catch((error) => {
        NotificationManager.error(error);
        return reject(error);
      });
  });
  return await apiPromise;
};

const contactUs = async (data) => {

  var apiPromise = new Promise((resolve, reject) => {
    axios
      .post(`${config.apiUrl}/contactUs`, data, {
        headers: {
          key: "2b223e5cee713615ha54ac203b24e9a123703mk411",
        },
      })
      .then(async (response) => {
        if (
          response.data.ResponseCode == 1 ||
          response.data.ResponseCode == "1"
        ) {
          return resolve(response.data);
        } else {
          return resolve(response.data);
        }
      })
      .catch((error) => {
        NotificationManager.error(error);
        return reject(error);
      });
  });
  return await apiPromise;
};

const exportPDFFile = async (data) => {
  const getToken = JSON.parse(localStorage.getItem("user"));

  var apiPromise = new Promise((resolve, reject) => {
    axios
      .post(`${config.apiUrl}/exportPDFFile`, data, {
        headers: {
          key: "2b223e5cee713615ha54ac203b24e9a123703mk411",
          token: getToken.token,
        },
      })
      .then(async (response) => {
        if (
          response.data.ResponseCode == 1 ||
          response.data.ResponseCode == "1"
        ) {
          return resolve(response.data);
        } else {
          return resolve(response.data);
        }
      })
      .catch((error) => {
        NotificationManager.error(error);
        return reject(error);
      });
  });
  return await apiPromise;
};

const createLocation = async (data) => {
  const getToken = JSON.parse(localStorage.getItem("user"));
  var apiPromise = new Promise((resolve, reject) => {
    axios
      .post(`${config.apiUrl}/createLocation`, data, {
        headers: {
          key: "2b223e5cee713615ha54ac203b24e9a123703mk411",
          token: getToken.token,
        },
      })
      .then(async (response) => {
        if (
          response.data.ResponseCode == 1 ||
          response.data.ResponseCode == "1"
        ) {
          return resolve(response.data);
        } else {
          return resolve(response.data);
        }
      })
      .catch((error) => {
        NotificationManager.error(error);
        return reject(error);
      });
  });
  return await apiPromise;
};
const deleteLocation = async (data) => {
  const getToken = JSON.parse(localStorage.getItem("user"));
  var apiPromise = new Promise((resolve, reject) => {
    axios
      .post(`${config.apiUrl}/deleteLocation`, data, {
        headers: {
          key: "2b223e5cee713615ha54ac203b24e9a123703mk411",
          token: getToken.token,
        },
      })
      .then(async (response) => {
        if (
          response.data.ResponseCode == 1 ||
          response.data.ResponseCode == "1"
        ) {
          return resolve(response.data);
        } else {
          return resolve(response.data);
        }
      })
      .catch((error) => {
        NotificationManager.error(error);
        return reject(error);
      });
  });
  return await apiPromise;
};
const getLocation = async (data) => {
  const getToken = JSON.parse(localStorage.getItem("user"));
  var apiPromise = new Promise((resolve, reject) => {
    axios
      .post(`${config.apiUrl}/getLocation`, data, {
        headers: {
          key: "2b223e5cee713615ha54ac203b24e9a123703mk411",
          token: getToken.token,
        },
      })
      .then(async (response) => {
        if (
          response.data.ResponseCode == 1 ||
          response.data.ResponseCode == "1"
        ) {
          return resolve(response.data);
        } else {
          return resolve(response.data);
        }
      })
      .catch((error) => {
        NotificationManager.error(error);
        return reject(error);
      });
  });
  return await apiPromise;
};
export {
  signUp,
  login,
  updateProfile,
  clockInclockOut,
  workImage,
  queryImage,
  serviceList,
  LogOut,
  contactUs,
  getEmployeeDetail,
  clockInclockOutUserList,
  exportPDFFile,
  forgotPassword,
  changePassword,
  getPropertyList,
  createLocation,
  deleteLocation,
  getLocation,
  servicesImages
};
