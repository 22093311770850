import React from "react";
import { Link } from "react-router-dom";

const Pagination = ({ postsPerPage, totalPosts, paginate, currentPage }) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumbers.push(i);
  }
  let totalPage = Math.ceil(totalPosts / postsPerPage);

  return (
    <nav className=" paginate-button">
      <div className="row">
        <div className="col-4">
          <ul className="pagination pagination-button text-center">
            {currentPage <= 1 && (
              <li className="page-item " style={{ pointerEvents: "none" }}>
                <Link
                  to="#?"
                  onClick={() => paginate(currentPage - 1)}
                  className="page-link prev-page"
                  tabIndex="-1"
                  role="button"
                  aria-label="Previous page"
                  rel="prev"
                  disabled
                >
                  <i className="fa-solid fa-caret-left"></i>
                </Link>
              </li>
            )}

            {currentPage > 1 && (
              <li className="page-item ">
                <Link
                  to="#?"
                  onClick={() => paginate(currentPage - 1)}
                  className="page-link prev-page"
                  tabIndex="-1"
                  role="button"
                  aria-label="Previous page"
                  rel="prev"
                >
                  <i className="fa-solid fa-caret-left"></i>
                </Link>
              </li>
            )}
            {currentPage >= totalPage && (
              <li className="page-item" style={{ pointerEvents: "none" }}>
                <Link
                  to="#?"
                  onClick={() => paginate(currentPage + 1)}
                  className="page-link next-page"
                  tabIndex="0"
                  role="button"
                  aria-label="Next page"
                  rel="next"
                >
                  <i className="fa-solid fa-caret-right"></i>
                </Link>
              </li>
            )}

            {currentPage < totalPage && (
              <li className="page-item">
                <Link
                  to="#?"
                  onClick={() => paginate(currentPage + 1)}
                  className="page-link next-page"
                  tabIndex="0"
                  role="button"
                  aria-label="Next page"
                  rel="next"
                >
                  <i className="fa-solid fa-caret-right"></i>
                </Link>
              </li>
            )}
          </ul>
        </div>
        <div className="col-8 d-sm-block d-none">
          <ul className="pagination number-btn pagination-button  text-center justify-content-end">
            {totalPage < 4 ? (
              <>
                {pageNumbers.map((number, key) => (
                  <li key={key} className="page-item">
                    <Link
                      onClick={() => paginate(number)}
                      to="#?"
                      className={`page-link ${
                        number === currentPage ? "active" : ""
                      }`}
                    >
                      {number}
                    </Link>
                  </li>
                ))}
              </>
            ) : currentPage % 4 >= 0 &&
              currentPage > 3 &&
              currentPage + 1 < totalPage ? (
              <>
                <li key={1} className="page-item">
                  <Link
                    onClick={() => paginate(1)}
                    to="#?"
                    className="page-link"
                  >
                    1
                  </Link>
                </li>

                <li className="page-item dot-item">
                  <Link
                    to="#?"
                    className="page-dots"
                    style={{ pointerEvents: "none" }}
                  >
                    ....
                  </Link>
                </li>

                <li key={currentPage - 1} className="page-item">
                  <Link
                    onClick={() => paginate(currentPage - 1)}
                    to="#?"
                    className="page-link"
                  >
                    {currentPage - 1}
                  </Link>
                </li>

                <li key={currentPage} className="page-item">
                  <Link
                    onClick={() => paginate(currentPage)}
                    to="#?"
                    className="page-link active"
                  >
                    {currentPage}
                  </Link>
                </li>

                <li key={currentPage + 1} className="page-item">
                  <Link
                    onClick={() => paginate(currentPage + 1)}
                    to="#?"
                    className="page-link"
                  >
                    {currentPage + 1}
                  </Link>
                </li>

                <li className="page-item dot-item">
                  <Link
                    to="#?"
                    className="page-dots"
                    style={{ pointerEvents: "none" }}
                  >
                    ....
                  </Link>
                </li>

                <li key={totalPage} className="page-item">
                  <Link
                    onClick={() => paginate(totalPage)}
                    to="#?"
                    className="page-link"
                  >
                    {totalPage}
                  </Link>
                </li>
              </>
            ): (
              <>
                <li key={1} className="page-item">
                  <Link
                    onClick={() => paginate(1)}
                    to="#?"
                    className={`page-link ${1 === currentPage ? "active" : ""}`}
                  >
                    1
                  </Link>
                </li>

                <li key={2} className="page-item">
                  <Link
                    onClick={() => paginate(2)}
                    to="#?"
                    className={`page-link ${2 === currentPage ? "active" : ""}`}
                  >
                    2
                  </Link>
                </li>
                <li key={3} className="page-item">
                  <Link
                    onClick={() => paginate(3)}
                    to="#?"
                    className={`page-link ${3 === currentPage ? "active" : ""}`}
                  >
                    3
                  </Link>
                </li>
                <li key={4} className="page-item">
                  <Link
                    onClick={() => paginate(4)}
                    to="#?"
                    className={`page-link ${4 === currentPage ? "active" : ""}`}
                  >
                    4
                  </Link>
                </li>

                <li className="page-item dot-item">
                  <Link
                    to="#?"
                    className="page-dots"
                    style={{ pointerEvents: "none" }}
                  >
                    ....
                  </Link>
                </li>

                <li key={totalPage} className="page-item">
                  <Link
                    onClick={() => paginate(totalPage)}
                    to="#?"
                    className={`page-link ${
                      totalPage === currentPage ? "active" : ""
                    }`}
                  >
                    {totalPage}
                  </Link>
                </li>
              </>
            )}
          </ul>
        </div>
        <div className="col-8 d-sm-none  d-block ">
          <ul className="pagination number-btn pagination-button  text-center justify-content-end">
            {totalPage < 2 ? (
              <>
                {pageNumbers.map((number, key) => (
                  <li key={key} className="page-item">
                    <Link
                      onClick={() => paginate(number)}
                      to="#?"
                      className={`page-link ${
                        number === currentPage ? "active" : ""
                      }`}
                    >
                      {number}
                    </Link>
                  </li>
                ))}
              </>
            ) : currentPage % 2 >= 0 &&
              currentPage > 1 &&
              currentPage + 1 < totalPage ? (
              <>
                <li key={1} className="page-item">
                  <Link
                    onClick={() => paginate(1)}
                    to="#?"
                    className="page-link"
                  >
                    1
                  </Link>
                </li>

                <li key={currentPage} className="page-item">
                  <Link
                    onClick={() => paginate(currentPage)}
                    to="#?"
                    className="page-link active"
                  >
                    {currentPage}
                  </Link>
                </li>

                <li key={currentPage + 1} className="page-item">
                  <Link
                    onClick={() => paginate(currentPage + 1)}
                    to="#?"
                    className="page-link"
                  >
                    {currentPage + 1}
                  </Link>
                </li>

                <li className="page-item dot-item">
                  <Link
                    to="#?"
                    className="page-dots"
                    style={{ pointerEvents: "none" }}
                  >
                    ....
                  </Link>
                </li>

                <li key={totalPage} className="page-item">
                  <Link
                    onClick={() => paginate(totalPage)}
                    to="#?"
                    className="page-link"
                  >
                    {totalPage}
                  </Link>
                </li>
              </>
            ) : currentPage % 2 >= 0 &&
              currentPage > 1 &&
              currentPage + 1 >= totalPage ? (
              <>
                <li key={1} className="page-item">
                  <Link
                    onClick={() => paginate(1)}
                    to="#?"
                    className="page-link"
                  >
                    1
                  </Link>
                </li>
                <li className="page-item dot-item">
                  <Link
                    to="#?"
                    className="page-dots"
                    style={{ pointerEvents: "none" }}
                  >
                    ....
                  </Link>
                </li>

                <li key={totalPage - 1} className="page-item">
                  <Link
                    onClick={() => paginate(totalPage - 1)}
                    to="#?"
                    className={`page-link ${
                      totalPage - 1 === currentPage ? "active" : ""
                    }`}
                  >
                    {totalPage - 1}
                  </Link>
                </li>

                <li key={totalPage} className="page-item">
                  <Link
                    onClick={() => paginate(totalPage)}
                    to="#?"
                    className={`page-link ${
                      totalPage === currentPage ? "active" : ""
                    }`}
                  >
                    {totalPage}
                  </Link>
                </li>
              </>
            ) : (
              <>
                <li key={1} className="page-item">
                  <Link
                    onClick={() => paginate(1)}
                    to="#?"
                    className={`page-link ${1 === currentPage ? "active" : ""}`}
                  >
                    1
                  </Link>
                </li>

                <li key={2} className="page-item">
                  <Link
                    onClick={() => paginate(2)}
                    to="#?"
                    className={`page-link ${2 === currentPage ? "active" : ""}`}
                  >
                    2
                  </Link>
                </li>

                <li className="page-item dot-item">
                  <Link
                    to="#?"
                    className="page-dots"
                    style={{ pointerEvents: "none" }}
                  >
                    ....
                  </Link>
                </li>

                <li key={totalPage} className="page-item">
                  <Link
                    onClick={() => paginate(totalPage)}
                    to="#?"
                    className={`page-link ${
                      totalPage === currentPage ? "active" : ""
                    }`}
                  >
                    {totalPage}
                  </Link>
                </li>
              </>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Pagination;

// import React from "react";
// import { Link } from "react-router-dom";

// const Pagination = ({ postsPerPage, totalPosts, paginate, currentPage }) => {
//   const pageNumbers = [];

//   for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
//     pageNumbers.push(i);
//   }
//   let totalPage = Math.ceil(totalPosts / postsPerPage);

//   return (
//     <nav className=" paginate-button">
//       <div className="row">
//         <div className="col-4">
//           <ul className="pagination pagination-button text-center">
//             {currentPage <= 1 && (
//               <li className="page-item " style={{ pointerEvents: "none" }}>
//                 <Link
//                   to="#?"
//                   onClick={() => paginate(currentPage - 1)}
//                   className="page-link prev-page"
//                   tabIndex="-1"
//                   role="button"
//                   aria-label="Previous page"
//                   rel="prev"
//                   disabled
//                 >
//                   <i className="fa-solid fa-caret-left"></i>
//                 </Link>
//               </li>
//             )}

//             {currentPage > 1 && (
//               <li className="page-item ">
//                 <Link
//                   to="#?"
//                   onClick={() => paginate(currentPage - 1)}
//                   className="page-link prev-page"
//                   tabIndex="-1"
//                   role="button"
//                   aria-label="Previous page"
//                   rel="prev"
//                 >
//                   <i className="fa-solid fa-caret-left"></i>
//                 </Link>
//               </li>
//             )}
//             {currentPage >= totalPage && (
//               <li className="page-item" style={{ pointerEvents: "none" }}>
//                 <Link
//                   to="#?"
//                   onClick={() => paginate(currentPage + 1)}
//                   className="page-link next-page"
//                   tabIndex="0"
//                   role="button"
//                   aria-label="Next page"
//                   rel="next"
//                 >
//                   <i className="fa-solid fa-caret-right"></i>
//                 </Link>
//               </li>
//             )}

//             {currentPage < totalPage && (
//               <li className="page-item">
//                 <Link
//                   to="#?"
//                   onClick={() => paginate(currentPage + 1)}
//                   className="page-link next-page"
//                   tabIndex="0"
//                   role="button"
//                   aria-label="Next page"
//                   rel="next"
//                 >
//                   <i className="fa-solid fa-caret-right"></i>
//                 </Link>
//               </li>
//             )}
//           </ul>
//         </div>
//         <div className="col-8">
//           <ul className="pagination number-btn pagination-button  text-center justify-content-end">
//             {totalPage < 5 ? (
//               <>
//                 {pageNumbers.map((number, key) => (
//                   <li key={key} className="page-item">
//                     <Link
//                       onClick={() => paginate(number)}
//                       to="#?"
//                       className={`page-link ${
//                         number === currentPage ? "active" : ""
//                       }`}
//                     >
//                       {number}
//                     </Link>
//                   </li>
//                 ))}
//               </>
//             ) : currentPage % 5 >= 0 &&
//               currentPage > 4 &&
//               currentPage + 2 < totalPage ? (
//               <>
//                 <li key={1} className="page-item">
//                   <Link
//                     onClick={() => paginate(1)}
//                     to="#?"
//                     className="page-link"
//                   >
//                     1
//                   </Link>
//                 </li>

//                 <li className="page-item">
//                   <Link
//                     to="#?"
//                     className="page-link"
//                     style={{ pointerEvents: "none" }}
//                   >
//                     ...
//                   </Link>
//                 </li>

//                 <li key={currentPage - 1} className="page-item">
//                   <Link
//                     onClick={() => paginate(currentPage - 1)}
//                     to="#?"
//                     className="page-link"
//                   >
//                     {currentPage - 1}
//                   </Link>
//                 </li>

//                 <li key={currentPage} className="page-item">
//                   <Link
//                     onClick={() => paginate(currentPage)}
//                     to="#?"
//                     className="page-link active"
//                   >
//                     {currentPage}
//                   </Link>
//                 </li>

//                 <li key={currentPage + 1} className="page-item">
//                   <Link
//                     onClick={() => paginate(currentPage + 1)}
//                     to="#?"
//                     className="page-link"
//                   >
//                     {currentPage + 1}
//                   </Link>
//                 </li>

//                 <li className="page-item">
//                   <Link
//                     to="#?"
//                     className="page-link"
//                     style={{ pointerEvents: "none" }}
//                   >
//                     ...
//                   </Link>
//                 </li>

//                 <li key={totalPage} className="page-item">
//                   <Link
//                     onClick={() => paginate(totalPage)}
//                     to="#?"
//                     className="page-link"
//                   >
//                     {totalPage}
//                   </Link>
//                 </li>
//               </>
//             ) : currentPage % 5 >= 0 &&
//               currentPage > 4 &&
//               currentPage + 2 >= totalPage ? (
//               <>
//                 <li key={1} className="page-item">
//                   <Link
//                     onClick={() => paginate(1)}
//                     to="#?"
//                     className="page-link"
//                   >
//                     1
//                   </Link>
//                 </li>

//                 <li className="page-item">
//                   <Link
//                     to="#?"
//                     className="page-link"
//                     style={{ pointerEvents: "none" }}
//                   >
//                     ...
//                   </Link>
//                 </li>

//                 <li key={totalPage - 3} className="page-item">
//                   <Link
//                     onClick={() => paginate(totalPage - 3)}
//                     to="#?"
//                     className={`page-link ${
//                       totalPage - 3 === currentPage ? "active" : ""
//                     }`}
//                   >
//                     {totalPage - 3}
//                   </Link>
//                 </li>
//                 <li key={totalPage - 2} className="page-item">
//                   <Link
//                     onClick={() => paginate(totalPage - 2)}
//                     to="#?"
//                     className={`page-link ${
//                       totalPage - 2 === currentPage ? "active" : ""
//                     }`}
//                   >
//                     {totalPage - 2}
//                   </Link>
//                 </li>
//                 <li key={totalPage - 1} className="page-item">
//                   <Link
//                     onClick={() => paginate(totalPage - 1)}
//                     to="#?"
//                     className={`page-link ${
//                       totalPage - 1 === currentPage ? "active" : ""
//                     }`}
//                   >
//                     {totalPage - 1}
//                   </Link>
//                 </li>

//                 <li key={totalPage} className="page-item">
//                   <Link
//                     onClick={() => paginate(totalPage)}
//                     to="#?"
//                     className={`page-link ${
//                       totalPage === currentPage ? "active" : ""
//                     }`}
//                   >
//                     {totalPage}
//                   </Link>
//                 </li>
//               </>
//             ) : (
//               <>
//                 <li key={1} className="page-item">
//                   <Link
//                     onClick={() => paginate(1)}
//                     to="#?"
//                     className={`page-link ${1 === currentPage ? "active" : ""}`}
//                   >
//                     1
//                   </Link>
//                 </li>

//                 <li key={2} className="page-item">
//                   <Link
//                     onClick={() => paginate(2)}
//                     to="#?"
//                     className={`page-link ${2 === currentPage ? "active" : ""}`}
//                   >
//                     2
//                   </Link>
//                 </li>
//                 <li key={3} className="page-item">
//                   <Link
//                     onClick={() => paginate(3)}
//                     to="#?"
//                     className={`page-link ${3 === currentPage ? "active" : ""}`}
//                   >
//                     3
//                   </Link>
//                 </li>
//                 <li key={4} className="page-item">
//                   <Link
//                     onClick={() => paginate(4)}
//                     to="#?"
//                     className={`page-link ${4 === currentPage ? "active" : ""}`}
//                   >
//                     4
//                   </Link>
//                 </li>

//                 <li key={5} className="page-item">
//                   <Link
//                     onClick={() => paginate(5)}
//                     to="#?"
//                     className={`page-link ${5 === currentPage ? "active" : ""}`}
//                   >
//                     5
//                   </Link>
//                 </li>

//                 <li className="page-item">
//                   <Link
//                     to="#?"
//                     className="page-link"
//                     style={{ pointerEvents: "none" }}
//                   >
//                     ...
//                   </Link>
//                 </li>

//                 <li key={totalPage} className="page-item">
//                   <Link
//                     onClick={() => paginate(totalPage)}
//                     to="#?"
//                     className={`page-link ${
//                       totalPage === currentPage ? "active" : ""
//                     }`}
//                   >
//                     {totalPage}
//                   </Link>
//                 </li>
//               </>
//             )}
//           </ul>
//         </div>
//       </div>
//     </nav>
//   );
// };

// export default Pagination;
