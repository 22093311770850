import React from "react";
import { useState } from "react";

const Test = () => {
  const [formValues, setFormValues] = useState([{ name: "", image: "" }]);

  let handleChange = (i, e) => {
    console.log('i', i,"e>",e,"e,targer",e.target.value)
    let newFormValues = [...formValues];
    newFormValues[i][e.target.name] = e.target.value;
    setFormValues(newFormValues);
  };

  const onSelectFile = (i,e) => {
    let newFormValues = [...formValues];
    console.log('i', i,"e>>>",e,"e,targer",e.target.files[0])
    newFormValues[i][e.target.name] = e.target.files[0];
    console.log('newFormValues', newFormValues)
    setFormValues(newFormValues);
  };
  let addFormFields = () => {
    setFormValues([...formValues, { name: "", image: "" }]);
  };

  let removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
  };

  let handleSubmit = (event) => {
    event.preventDefault();
    console.log('JSON.stringify(formValues)', JSON.stringify(formValues))
  };

  return (
    <form onSubmit={handleSubmit}>
      {formValues.map((element, index) => (
        <div className="form-inline" key={index}>
          <label>Name</label>
          <input
            type="text"
            name="name"
            value={element.name || ""}
            onChange={(e) => handleChange(index, e)}
          />
          <label>image</label>
          <input
            type="file"
            name="image"
            onChange={(e) => onSelectFile(index, e)}
          />
          {index ? (
            <button
              type="button"
              className="button remove"
              onClick={() => removeFormFields(index)}
            >
              Remove
            </button>
          ) : null}
        </div>
      ))}
      <div className="button-section">
        <button
          className="button add"
          type="button"
          onClick={() => addFormFields()}
        >
          Add
        </button>
        <button className="button submit" type="submit">
          Submit
        </button>
      </div>
    </form>
  );
};

export default Test;

// class CheckboxForm extends React.Component {
//   constructor() {
//     super();
//     this.state = {
//       hobbies: [],
//     };
//     this.handleInputChange = this.handleInputChange.bind(this);
//   }
//   handleInputChange(event) {
//     const target = event.target;
//     var value = target.value;
//     if (target.checked) {
//         // this.setState({hobbies[value] = value})
//       this.state.hobbies[value] = value;
//     } else {
//       this.state.hobbies.splice(value, "");
//     }
//   }
//   submit() {
//     console.warn(this.state);
//   }
//   render() {
//     return (
//       <div>
//         <div class="row">
//           <div class="col-md-6 offset-md-3">
//             <br />
//             <br />
//             <h3>
//               To Get Multiple Checked Checkbox Values On Submit in React JS -
//               Tutsmake.com
//             </h3>
//             <br />
//             <div class="form-row">
//               <div class="form-group col-md-6">
//                 <label>Hobbies :</label>
//                 <br />
//                 <div class="form-check form-check-inline">
//                   <input
//                     class="form-check-input"
//                     type="checkbox"
//                     name="hobbies"
//                     id="inlineCheckboxh1"
//                     value="1"
//                     onChange={this.handleInputChange}
//                   />
//                   <label class="form-check-label" for="inlineCheckboxh1">
//                     Reading
//                   </label>
//                 </div>
//                 <div class="form-check form-check-inline">
//                   <input
//                     class="form-check-input"
//                     type="checkbox"
//                     name="hobbies"
//                     id="inlineCheckboxh2"
//                     value="2"
//                     onChange={this.handleInputChange}
//                   />
//                   <label class="form-check-label" for="inlineCheckboxh2">
//                     Developing
//                   </label>
//                 </div>
//                 <div class="form-check form-check-inline">
//                   <input
//                     class="form-check-input"
//                     type="checkbox"
//                     name="hobbies"
//                     id="inlineCheckboxh3"
//                     value="3"
//                     onChange={this.handleInputChange}
//                   />
//                   <label class="form-check-label" for="inlineCheckboxh3">
//                     Desiging
//                   </label>
//                 </div>
//               </div>
//             </div>
//             <div class="form-row">
//               <div class="col-md-12 text-center">
//                 <button
//                   type="submit"
//                   class="btn btn-primary"
//                   onClick={() => this.submit()}
//                 >
//                   Submit
//                 </button>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     );
//   }
// }
// export default CheckboxForm;
