import React, { useEffect, useState } from "react";
import Calendar from "react-calendar";
import Modal from "react-modal";
import { GoogleMap, Marker } from "@react-google-maps/api";
import $ from "jquery";
import "react-calendar/dist/Calendar.css";
import config from "../common/config";
import Clock from "react-clock";
import { NotificationManager } from "react-notifications";
import {
  clockInclockOut,
  clockInclockOutUserList,
  getPropertyList,
  queryImage,
  serviceList,
  servicesImages,
  workImage,
} from "../services/services";
import { useForm } from "react-hook-form";
import moment from "moment";
import dayjs from "dayjs";
import axios from "axios";

const ClockCalender = () => {
  const [modalClockIn, setClockIn] = useState(false);
  const [modalClockOut, setClockOut] = useState(false);
  const [propertyModal, setPropertyModal] = useState(false);
  const [propertyOut, setPropertyOut] = useState(false);
  const [unit, setUnit] = useState("");
  const [building, setBuilding] = useState("");
  const [serviceData, setServiceData] = useState();
  const [services, setService] = useState("");
  const [selectProperty, setSelectProperty] = useState("");
  const [propertyData, setPropertyData] = useState("");

  const [startDate, setStartDate] = useState(new Date());
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [location, setLocation] = useState(false);
  const [locationError, setLocationError] = useState(true);
  const [address, setAddress] = useState("");
  const [defaultCenter, setDefaultCenter] = useState({
    lat: 19.20351,
    lng: 72.839233,
  });

  const isLogin = localStorage.getItem("isLogged");
  const user = JSON.parse(localStorage.getItem("user"));
  const isClockIn = JSON.parse(localStorage.getItem("isClockIn"));
  const locate = localStorage.getItem("location");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const getService = localStorage.getItem("selectService");

  const ServiceList = async () => {
    var formData = new FormData();

    formData.append("user_id", user.manager_id);
    var apiResponse = await serviceList(formData);
    if (apiResponse.ResonseCode == 1 || apiResponse.ResponseCode == "1") {
      setServiceData(apiResponse.data);
      console.log("selecServices", apiResponse.data);
    }
  };
  const propertyList = async () => {
    var formData = new FormData();

    formData.append("user_id", user.user_id);
    var apiResponse = await getPropertyList(formData);
    if (apiResponse.ResonseCode == 1 || apiResponse.ResponseCode == "1") {
      setPropertyData(apiResponse.data);
      console.log("selectProperty", apiResponse.data);
    }
  };

  useEffect(() => {
    ServiceList();
    propertyList();
    console.log("HELLO");
    console.log("serviceData", serviceData);
  }, []);

  let date = new Date();

  let curHour = date.getHours() >= 10 ? date.getHours() : "0" + date.getHours();
  let curMin =
    date.getMinutes() >= 10 ? date.getMinutes() : "0" + date.getMinutes();
  let curSec =
    date.getSeconds() >= 10 ? date.getSeconds() : "0" + date.getSeconds();

  var clockInDate = localStorage.getItem("clockIn");
  var clockOutDate = localStorage.getItem("clockOut");

  const [hourClick, setHourClick] = useState(
    clockInDate == "" || clockInDate == null || clockInDate == "Invalid date"
      ? curHour
      : moment(clockInDate, "hh:mm:ss").format("HH")
  );
  const [minClick, setminClick] = useState(
    clockInDate == "" || clockInDate == null || clockInDate == "Invalid date"
      ? curMin
      : moment(clockInDate, "hh:mm:ss").format("mm")
  );
  const [secClick, setSecClick] = useState(
    clockInDate == "" || clockInDate == null || clockInDate == "Invalid date"
      ? curSec
      : moment(clockInDate, "hh:mm:ss").format("ss")
  );
  const [outHourClk, setOutHourClk] = useState(
    clockOutDate == "" || clockOutDate == null || clockOutDate == "Invalid date"
      ? curHour
      : moment(clockOutDate, "hh:mm:ss").format("HH")
  );
  const [outMinClk, setOutMinClk] = useState(
    clockOutDate == "" || clockOutDate == null || clockOutDate == "Invalid date"
      ? curMin
      : moment(clockOutDate, "hh:mm:ss").format("mm")
  );
  const [outsecClk, setOutSecClk] = useState(
    clockOutDate == "" || clockOutDate == null || clockOutDate == "Invalid date"
      ? curSec
      : moment(clockOutDate, "hh:mm:ss").format("ss")
  );

  // clockInModal
  function openClockInModal(e) {
    setClockIn(true);
  }
  function closeClockInModal() {
    setClockIn(false);
  }

  // clockOutModal
  function openClockOutModal(e) {
    setClockOut(true);
  }
  function closeClockOutModal() {
    setClockOut(false);
  }

  // propertyModal
  function openPropertyModal(e) {
    e.preventDefault();
    setPropertyModal(true);
  }
  function closePropertyModal() {
    setPropertyModal(false);
  }

  // propertyOutModal
  function propertyOutModal(e) {
    e.preventDefault();
    setPropertyOut(true);
  }
  function closePropertyOutModal() {
    setPropertyOut(false);
  }
  const mapStyles = {
    height: "350px",
    width: "100%",
  };

  function clockInProperty(e) {
    openClockInModal(true);
    closePropertyModal(true);
  }
  function clockOutProperty(e) {
    openClockOutModal(true);
    closePropertyOutModal(true);
  }

  const fetchLocationName = async (lat, lng) => {
    var apiPromise = new Promise((resolve, reject) => {
      axios
        .get(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyCXREt_f6WG9lVFRpl0Nco8ZbmhzaXcoqM`
        )
        .then(async (response) => {
          if (
            response.data.ResponseCode == 1 ||
            response.data.ResponseCode == "1"
          ) {
            return resolve(response.data.results[0].formatted_address);
          } else {
            return resolve(response.data.results[0].formatted_address);
          }
        })
        .catch((error) => {
          NotificationManager.error(error);
          return reject(error);
        });
    });
    return await apiPromise;
  };

  // posts.forEach(async (element) => {
  //   // console.log("element", element);
  //   var lat = element.lat;
  //   var lng = element.lng;
  //   // console.log(lat);

  //   element.location = await fetchLocationName(lat, lng);
  //   // console.log("lat, lng", fetchLocationName(lat, lng));
  // });

  function getLocation() {
    setLocation(true);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition, showError);
    }
  }
  async function showPosition(position) {
    setDefaultCenter((previousState) => {
      console.log("latlng", defaultCenter);
      return {
        ...previousState,
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      };
    });
    var apiPromise = new Promise((resolve, reject) => {
      axios
        .get(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.coords.latitude},${position.coords.longitude}&key=AIzaSyCXREt_f6WG9lVFRpl0Nco8ZbmhzaXcoqM`
        )
        .then(async (response) => {
          if (response.status == 200 || response.status == "200") {
            const location_address = response.data.results[0].formatted_address;
            localStorage.setItem("location", location_address);
            setAddress(location_address);
            console.log("Locationaddress------------->", location_address);
            setLocationError(false);
            return resolve(location_address);
          } else {
            NotificationManager.error("Something went wrong");
          }
        })
        .catch((error) => {
          NotificationManager.error(error);
          return reject(error);
        });
    });

    return await apiPromise;
  }

  // showError
  function showError(error) {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        NotificationManager.error("Please enable location.");
        setLocationError(true);
        break;
      case error.POSITION_UNAVAILABLE:
        NotificationManager.error("Location information is unavailable.");
        setLocationError(true);
        break;
      case error.TIMEOUT:
        NotificationManager.error(
          "The request to get user location timed out."
        );
        setLocationError(true);
        break;
      case error.UNKNOWN_ERROR:
        NotificationManager.error("An unknown error occurred.");
        setLocationError(true);
        break;

      default:
        NotificationManager.error("An unknown error occurred.");
        setLocationError(true);
    }
  }

  //uploadPhoto
  $(document).ready(() => {
    $("#uploadPhoto").change(function () {
      const file = this.files[0];
      if (file) {
        let reader = new FileReader();
        reader.onload = function (event) {
          $("#imgPreview").attr("src", event.target.result);
        };
        reader.readAsDataURL(file);
      }
    });
  });

  //uploadQueryPhoto
  $(document).ready(() => {
    $("#queryfile").change(function () {
      const file = this.files[0];
      if (file) {
        let reader = new FileReader();
        reader.onload = function (event) {
          $("#imgQueryPreview").attr("src", event.target.result);
        };
        reader.readAsDataURL(file);
      }
    });
  });
  const clockIn = async () => {
    $("#loadSpins").removeAttr("hidden", "hidden");
    $(".hide-modal ").css("opacity", ".1");
    $(".select").css("pointer-events", "none");

    if (
      location == "false" ||
      location == false ||
      locationError == true ||
      locationError == "true"
    ) {
      $("#loadSpins").attr("hidden", "hidden");
      $(".hide-modal ").css("opacity", "1");
      $(".select").css("pointer-events", "fill");
      NotificationManager.error("Please select current location");
      return false;
    }

    var formData = new FormData();
    formData.append("user_id", user.user_id);
    formData.append("type", "clock_in");
    // formData.append("building_name", building);
    formData.append("property_name", selectProperty);
    // formData.append("unit_number", unit);
    formData.append("location", address);
    formData.append("lat", defaultCenter.lat);
    formData.append("lng", defaultCenter.lng);
    formData.append("services", services);
    formData.append("date_time",moment(date).format("YYYY-MM-DD HH:mm:ss"));
    console.log("selectPropertyselectProperty", selectProperty);
    console.log("formDataOnClockInLocaton ----------------->", formData);
    const apiResponse = await clockInclockOut(formData);

    if (apiResponse.ResponseCode == 1 || apiResponse.ResponseCode == "1") {
      $("#loadSpins").attr("hidden", "hidden");
      $(".hide-modal ").css("opacity", "1");
      $(".select").css("pointer-events", "fill");
      localStorage.setItem("selectService", services);
      console.log(
        "formDataOnClockInLocatonAPIRES ----------------->",
        apiResponse
      );

      NotificationManager.success(apiResponse.ResponseMsg);
      console.log("apiResponse---->>>>>", apiResponse.data);
      var getLocalData = JSON.parse(localStorage.getItem("user"));
      getLocalData.clocking_dtl = apiResponse.data.clocking_dtl;
      // setClockIn(true);

      localStorage.setItem("user", JSON.stringify(getLocalData));
      const clockIn = apiResponse.data.clock_in_out_detail.map((e) => {
        return e.clock_in;
      });

      setLocation(false);
      console.log("clockIn", apiResponse);
      const arrayLength = clockIn.length - 1;
      let newclockIn = moment(clockIn[arrayLength]).format("hh:mm:ss");

      // let newclockIn = convertUTCDateToLocalDate(
      //   new Date(clockIn[arrayLength])
      // );
      localStorage.setItem("clockIn", newclockIn);
      localStorage.setItem(
        "isClockIn",
        JSON.stringify({
          is_clock_out: "0",
          is_clock_in: "1",
        })
      );
      let clockInTime = newclockIn.split(" ")[1];
      const hour = newclockIn.split(":");
      console.log("hour", hour);
      setHourClick(hour[0]);

      setminClick(hour[1]);

      setSecClick(hour[2]);
      closeClockInModal();
      // window.location.reload();
    } else {
      $("#loadSpins").attr("hidden", "hidden");
      $(".hide-modal ").css("opacity", "1");
      $(".select").css("pointer-events", "fill");
      NotificationManager.error(apiResponse.ResponseMsg);
    }
  };

  const clockOut = async () => {
    $("#loadSpins").removeAttr("hidden", "hidden");
    $(".hide-modal ").css("opacity", ".1");
    $(".select").css("pointer-events", "none");

    // var image = [];
    // $("input[name='imageClockOut']").each(function () {
    //   image.push(this.value);
    // });
    var formData = new FormData();

    formData.append("user_id", user && user.user_id);
    formData.append("location", address);
    formData.append("type", "clock_out");
    formData.append("property_name", selectProperty);
    console.log("selectPropertyselectProperty", selectProperty);
    formData.append("date_time",moment(date).format("YYYY-MM-DD HH:mm:ss"));

    // var totalfiles = document.getElementById("files").files.length;
    // for (var index = 0; index < totalfiles; index++) {
    //   formData.append("image[]", document.getElementById("files").files[index]);
    // }
    // ServicesImages();

    const apiResponse = await clockInclockOut(formData);
    if (apiResponse.ResponseCode == 1 || apiResponse.ResponseCode == "1") {
      $("#loadSpins").attr("hidden", "hidden");
      $(".hide-modal ").css("opacity", "1");
      $(".select").css("pointer-events", "fill");

      NotificationManager.success(apiResponse.ResponseMsg);
      const clockOut = apiResponse.data.clock_in_out_detail.map((e) => {
        return e.clock_out;
      });

      var getLocalData = JSON.parse(localStorage.getItem("user"));
      getLocalData.clocking_dtl = "";
      // setClockIn(true);
      localStorage.setItem("user", JSON.stringify(getLocalData));

      setLocation(false);
      const arrayLength = clockOut.length - 1;
      let newclockOut = moment(clockOut[arrayLength]).format("hh:mm:ss");
      
      
      // let newclockOut = moment
      //   .utc(clockOut[arrayLength])
      //   .local()
      //   .format("hh:mm:ss");
      // let newclockOut = convertUTCDateToLocalDate( new Date(clockOut[arrayLength]) );

      localStorage.setItem("clockOut", newclockOut);
      localStorage.setItem(
        "isClockIn",
        JSON.stringify({ is_clock_out: "1", is_clock_in: "1" })
      );

      let clockOutTime = newclockOut.split(" ")[1];

      const hour = newclockOut.split(":");

      setOutHourClk(hour[0]);
      setOutMinClk(hour[1]);
      setOutSecClk(hour[2]);

      closeClockOutModal();
      // window.location.reload();
    } else {
      $("#loadSpins").attr("hidden", "hidden");
      $(".hide-modal ").css("opacity", "1");
      $(".select").css("pointer-events", "fill");

      NotificationManager.error(apiResponse.ResponseMsg);
    }
  };

  const ServicesImages = async () => {
    $("#loadSpins").removeAttr("hidden", "hidden");
    $(".hide-modal ").css("opacity", ".1");
    $(".select").css("pointer-events", "none");

    let totalImage;
    let totalImg = $("input[name='sub_service_img']").length; // 2
    let totalCall = Math.ceil(totalImg / 20);
    console.log("totalCall", totalCall);

    for (let index = 0; index < totalCall; index++) {
      $("#loadSpins").removeAttr("hidden", "hidden");
      $(".hide-modal ").css("opacity", ".1");
      $(".select").css("pointer-events", "none");
      var formData = new FormData();
      let maximage = 20;
      let imageArrey = [];
      let descArry = [];
      console.log("index", index, "totalCall", totalCall - 1);
      $("input[name='sub_service_img']").each(function (i) {
        imageArrey.push($(this));
      });

      $("input[name='desc']").each(function () {
        descArry.push($(this));
      });

      for (
        let x = maximage * index;
        x < maximage * (index + 1) && x < imageArrey.length;
        x++
      ) {
        let image = imageArrey[x];
        let id = $(image).attr("id");
        totalImage = document.getElementById(id).files.length;
        for (let index = 0; index < totalImage; index++) {
          formData.append("image[]", document.getElementById(id).files[index]);
        }

        let desc = descArry[x];
        let Descid = $(desc).attr("id");
        let totalDesc = document.getElementById(Descid).value;
        formData.append("description[]", totalDesc);
      }

      formData.append("user_id", user && user.user_id);
      formData.append("location", address);
      formData.append("type", "clock_out");
      formData.append("property_name", selectProperty);
      const apiResponse = await servicesImages(formData);
      if (apiResponse.ResponseCode == 1 || apiResponse.ResponseCode == "1") {
        $("#loadSpins").attr("hidden", "hidden");
        $(".hide-modal ").css("opacity", "1");
        $(".select").css("pointer-events", "fill");
        if (index === totalCall - 1) {
          clockOut();
        }
      } else {
        $("#loadSpins").attr("hidden", "hidden");
        $(".hide-modal ").css("opacity", "1");
        $(".select").css("pointer-events", "fill");
        NotificationManager.error(apiResponse.ResponseMsg);
      }
    }

    // $("input[name='sub_service_img']").each(function (i) {
    //   // image.push(this.value);
    //   let id = $(this).attr("id");
    //   totalImage = document.getElementById(id).files.length;
    //   for (var index = 0; index < totalImage; index++) {
    //     formData.append("image[]", document.getElementById(id).files[index]);
    //   }
    // });

    // let totalDesc;
    // $("input[name='desc']").each(function () {
    //   let id = $(this).attr("id");
    //   totalDesc = document.getElementById(id).value;
    //   formData.append("description[]", totalDesc);
    // });

    // if (totalImg > 20) {
    //   $("#loadSpins").attr("hidden", "hidden");
    //   $(".hide-modal ").css("opacity", "1");
    //   $(".select").css("pointer-events", "fill");
    //   NotificationManager.error("Select maximum 20 photos");
    //   return;
    // }
  };

  const uploadPhoto = async (e) => {
    $("#loadSpins").removeAttr("hidden", "hidden");
    $(".upload-modal").css("opacity", ".1");
    $(".select").css("pointer-events", "none");

    var image = [];
    var formData = new FormData();

    formData.append("user_id", user && user.user_id);

    $("input[name='sub_service_img']").each(function () {
      // image.push(this.value);
      let id = $(this).attr("id");
      let totalImage = document.getElementById(id).files.length;
      for (var index = 0; index < totalImage; index++) {
        formData.append("image[]", document.getElementById(id).files[index]);
      }
    });

    // if(document.getElementById("uploadPhoto1")){
    //   var totalfiles = document.getElementById("uploadPhoto1").files.length;
    // for (var index = 0; index < totalfiles; index++) {
    //   formData.append(
    //     "image[]",
    //     document.getElementById("uploadPhoto1").files[index]
    //   );
    // }
    // }

    // if(document.getElementById("uploadPhoto2")){
    //   var totalfiles2 = document.getElementById("uploadPhoto2").files.length;
    // for (var index2 = 0; index2 < totalfiles2; index2++) {
    //   formData.append(
    //     "image[]",
    //     document.getElementById("uploadPhoto2").files[index2]
    //   );
    // }
    // }

    // if(document.getElementById("uploadPhoto3")){
    //   var totalfiles3 = document.getElementById("uploadPhoto3").files.length;
    // for (var index3 = 0; index3 < totalfiles3; index3++) {
    //   formData.append(
    //     "image[]",
    //     document.getElementById("uploadPhoto3").files[index3]
    //   );
    // }

    // }

    var apiResponse = await workImage(formData);
    if (apiResponse.ResonseCode == 1 || apiResponse.ResponseCode == "1") {
      $("#loadSpins").attr("hidden", "hidden");
      $(".upload-modal").css("opacity", "1");
      $(".select").css("pointer-events", "fill");
      NotificationManager.success(apiResponse.ResponseMsg);
      localStorage.setItem("photos", JSON.stringify(apiResponse.data));
      // window.location.reload();
      clockOut();
      closePropertyModal();
    } else {
      $("#loadSpins").attr("hidden", "hidden");
      $(".upload-modal").css("opacity", "1");
      $(".select").css("pointer-events", "fill");
      NotificationManager.error(apiResponse.ResponseMsg);
    }
  };

  const queryImages = async (e) => {
    $("#loadSpins").removeAttr("hidden", "hidden");
    $(".time-row ").css("opacity", ".1");
    $(".select").css("pointer-events", "none");

    var image = [];
    $("input[name='queryImages']").each(function () {
      image.push(this.value);
    });

    var formData = new FormData();
    formData.append("user_id", user && user.user_id);
    var totalfiles = document.getElementById("queryfile").files.length;
    for (var index = 0; index < totalfiles; index++) {
      formData.append(
        "image[]",
        document.getElementById("queryfile").files[index]
      );
    }

    var apiResponse = await queryImage(formData);
    if (apiResponse.ResonseCode == 1 || apiResponse.ResponseCode == "1") {
      $("#loadSpins").attr("hidden", "hidden");
      $(".time-row ").css("opacity", "1");
      $(".select").css("pointer-events", "fill");
      NotificationManager.success(apiResponse.ResponseMsg);
      localStorage.setItem("problemPhotos", JSON.stringify(apiResponse.data));
      // window.location.reload();
    } else {
      $("#loadSpins").attr("hidden", "hidden");
      $(".time-row ").css("opacity", "1");
      $(".select").css("pointer-events", "fill");
      NotificationManager.error(apiResponse.ResponseMsg);
    }
  };

  const logInErr = (e) => {
    e.preventDefault();
    NotificationManager.error("User is already clockin");
  };
  const IsClockOut = () => {
    NotificationManager.error("Please first clockin");
  };

  const clockAllDate = [];
  const apiDateArr = [];

  const clockInOutUserList = async () => {
    var formData = new FormData();
    formData.append("user_id", user && user.user_id);
    formData.append("month", month);
    formData.append("year", year);
    var apiResponse = await clockInclockOutUserList(formData);

    if (apiResponse.ResonseCode == 1 || apiResponse.ResponseCode == "1") {
      var apiClockDate = apiResponse.data.map((data) => data.clock_out);
      for (let index = 0; index < apiClockDate.length; index++) {
        var element = moment(apiClockDate[index]).format("DD/MM/YYYY");
        apiDateArr.push(element);
      }

      // calender date list
      $(".react-calendar__tile").each(function (i, obj) {
        let date = moment(this.children[0].ariaLabel).format("DD/MM/YYYY");
        clockAllDate.push(date);
      });
      let activeDate = new Date();
      for (let clockDate = 0; clockDate < clockAllDate.length; clockDate++) {
        for (let apiDate = 0; apiDate < apiDateArr.length; apiDate++) {
          let clockedDateList = moment(
            apiDateArr[apiDate],
            "DD/MM/YYYY"
          ).format("MMM D, YYYY");
          if (clockAllDate[clockDate] == apiDateArr[apiDate]) {
            $(
              ".react-calendar__tile abbr[aria-label='" + clockedDateList + "']"
            ).addClass("success");

            $(
              ".react-calendar__tile abbr[aria-label!='" +
                clockedDateList +
                "']"
            ).addClass("pending");
          } else if (
            moment(activeDate).format("X") <
            moment(clockAllDate[clockDate], "DD/MM/YYYY").format("X")
          ) {
            let futureDate = moment(
              clockAllDate[clockDate],
              "DD/MM/YYYY"
            ).format("MMM D, YYYY");
            $(
              ".react-calendar__tile abbr[aria-label='" + futureDate + "']"
            ).removeClass("pending");
          }
        }

        // X refer Unix ms timestamp miliseconds
        if (
          moment(activeDate).format("X") <
          moment(clockAllDate[clockDate], "DD/MM/YYYY").format("X")
        ) {
          let clockedDateList = moment(
            clockAllDate[clockDate],
            "DD/MM/YYYY"
          ).format("MMMM D, YYYY");
          $(
            ".react-calendar__tile abbr[aria-label='" + clockedDateList + "']"
          ).removeClass("pending");
        }
      }
    }
  };
  const handleUserClockInOut = (data) => {
    setStartDate(data);
    setMonth(moment(data).format("MM"));
    setYear(moment(data).format("YYYY"));
  };

  function convertUTCDateToLocalDate(date) {
    var newDate = new Date(
      date.getTime() + date.getTimezoneOffset() * 60 * 1000
    );

    var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();

    newDate.setHours(hours - offset);
    let dformat =
      [
        newDate.getFullYear(),
        newDate.getMonth() + 1 >= 10
          ? newDate.getMonth() + 1
          : "0" + (newDate.getMonth() + 1),
        newDate.getDate() >= 10 ? newDate.getDate() : "0" + newDate.getDate(),
      ].join("-") +
      " " +
      [
        newDate.getHours() >= 10
          ? newDate.getHours()
          : "0" + newDate.getHours(),
        newDate.getMinutes() >= 10
          ? newDate.getMinutes()
          : "0" + newDate.getMinutes(),
        newDate.getSeconds() >= 10
          ? newDate.getSeconds()
          : "0" + newDate.getSeconds(),
      ].join(":");
    return dformat;
  }

  useEffect(() => {
    clockInOutUserList();
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    var date = convertUTCDateToLocalDate(new Date("2022-05-02 09:54:00"));
  }, [startDate]);

  // const initialFormState = { id: 0, name: "" };
  const [fields, setFields] = useState([]);
  const addField = (name, i) => {
    let count = 1;
    for (let index = 0; index < fields.length; index++) {
      const element = fields[index].name;
      console.log("element", element);
      if (element === name) {
        console.log("true");
        count = count + 1;
      }
    }
    if (count >= 20) {
      return;
    }
    let fieldData = {};
    fieldData.name = name;
    fieldData.id = fields.length + i;
    setFields([...fields, fieldData]);
  };

  const deleteField = async (id) => {
    setFields(fields.filter((user) => user.id !== id));
  };

  return (
    <>
      {isLogin === null ||
      isLogin === "" ||
      isLogin === "false" ||
      isLogin === false ? (
        ""
      ) : user &&
        (user.user_type === "Manager" ||
          user.user_type === "Property Manager") ? (
        ""
      ) : (
        <>
          <div id="service" className="time-container">
            <div className="row time-row">
              <div className="col-lg-6 col-12">
                <div className="row d-flex justify-content-center align-items-center  flex-column">
                  <div className="clock-in" id="clock">
                    <div className="col-sm-6 col-12 clock">
                      <div className="Page">
                        <Clock
                          className="Watch"
                          hourHandLength={70}
                          minuteHandLength={80}
                          secondHandLength={85}
                          hourHandOppositeLength={18}
                          value={`${hourClick}:${minClick}:${secClick}`}
                          hourMarksLength={10}
                          size={400}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-12 clock-time">
                      <div className="clock-form">
                        <form onSubmit={(e) => e}>
                          <div className="time-box">
                            <input
                              placeholder="10"
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              className="time-input semi-col"
                              maxLength={2}
                              type="text"
                              value={hourClick}
                              // onChange={(e) => setHourIn(e.target.value)}
                              required
                            />
                            <span className="dots">:</span>
                            <input
                              placeholder="10"
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              className="time-input semi-col"
                              maxLength={2}
                              minLength={2}
                              type="text"
                              // onChange={(e) => setMinIn(e.target.value)}
                              value={minClick}
                              required
                            />
                            <span className="dots">:</span>
                            <input
                              placeholder="10"
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              className="time-input semi-col"
                              maxLength={2}
                              minLength={2}
                              type="text"
                              // onChange={(e) => setSecIn(e.target.value)}
                              value={secClick}
                              required
                            />
                          </div>
                        </form>

                        {isClockIn &&
                        isClockIn.is_clock_in == isClockIn.is_clock_out ? (
                          <form>
                            <button
                              type="submit"
                              className="select"
                              onClick={openPropertyModal}
                            >
                              Clock In
                              <span>
                                <img
                                  className="plus"
                                  src={
                                    config.appBaseName + "images/white-plus.png"
                                  }
                                  alt=""
                                />
                              </span>
                            </button>
                          </form>
                        ) : (
                          <form>
                            <button
                              type="submit"
                              className="select not-allowed"
                              onClick={logInErr}
                              disabled
                            >
                              Clock In
                              <span>
                                <img
                                  className="plus"
                                  src={
                                    config.appBaseName + "images/white-plus.png"
                                  }
                                  alt=""
                                />
                              </span>
                            </button>
                          </form>
                        )}
                        {/* {isClockin &&
                        (isClockin == true || isClockin == "true") ? (
                          <form>
                            <button
                              type="submit"
                              className="select"
                              onClick={openClockInModal}
                            >
                              Clock In
                              <span>
                                <img
                                  className="plus"
                                  src={
                                    config.appBaseName + "images/white-plus.png"
                                  }
                                  alt=""
                                />
                              </span>
                            </button>
                          </form>
                        ) : (
                          <button
                            type="submit"
                            className="select"
                            onClick={logInErr}
                          >
                            Clock in
                            <span>
                              <img
                                className="plus"
                                src={
                                  config.appBaseName + "images/white-plus.png"
                                }
                                alt=""
                              />
                            </span>
                          </button>
                        )} */}
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="clock-out ">
                    <div className="col-sm-6 col-12 clock">
                      <div className="Page">
                        <Clock
                          className="Watch"
                          hourHandLength={70}
                          minuteHandLength={80}
                          secondHandLength={85}
                          hourHandOppositeLength={18}
                          value={`${outHourClk}:${outMinClk}:${outsecClk}`}
                          hourMarksLength={10}
                          size={400}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-12 clock-time">
                      <div className="clock-form">
                        <form>
                          <div className="time-box">
                            <input
                              placeholder="12"
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              className="time-input semi-col"
                              maxLength={2}
                              value={outHourClk}
                              // onChange={(e) => setHourOut(e.target.value)}
                              type="text"
                              required
                            />
                            <span className="dots">:</span>
                            <input
                              placeholder="15"
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              className="time-input semi-col"
                              maxLength={2}
                              minLength={2}
                              value={outMinClk}
                              // onChange={(e) => setMinOut(e.target.value)}
                              type="text"
                              required
                            />
                            <span className="dots">:</span>
                            <input
                              placeholder="30"
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              className="time-input semi-col"
                              maxLength={2}
                              minLength={2}
                              value={outsecClk}
                              // onChange={(e) => setSecOut(e.target.value)}
                              type="text"
                              required
                            />
                          </div>
                        </form>

                        {isClockIn &&
                        isClockIn.is_clock_in !== isClockIn.is_clock_out ? (
                          <button
                            type="submit"
                            className="select"
                            onClick={propertyOutModal}
                          >
                            Clock Out
                            <span>
                              <img
                                className="plus"
                                src={
                                  config.appBaseName + "images/white-plus.png"
                                }
                                alt=""
                              />
                            </span>
                          </button>
                        ) : (
                          <button
                            type="submit"
                            className="select not-allowed"
                            onClick={IsClockOut}
                            disabled
                          >
                            Clock Out
                            <span>
                              <img
                                className="plus"
                                src={
                                  config.appBaseName + "images/white-plus.png"
                                }
                                alt=""
                              />
                            </span>
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                  <hr />
                  {isClockIn &&
                  isClockIn.is_clock_in !== isClockIn.is_clock_out ? (
                    <div className="upload-y" id="workphoto">
                      <form action="">
                        {/* onSubmit={handleSubmit(uploadPhoto)} */}
                        <div className="priview-y">
                          <div className="house-wrapper-y">
                            <label className="property-from-label-y">
                              <img
                                className="sell-property-image-y"
                                src={
                                  config.appBaseName + "images/preview_img.png"
                                }
                                alt=""
                              />
                              <div className="picture-preview-y">
                                <img
                                  id="imgPreview"
                                  className="set-show-preview"
                                  src={
                                    config.appBaseName +
                                    "images/preview_img.png"
                                  }
                                  alt=""
                                />

                                {/* <img
                          src={preview.image}
                          alt="Lounge"
                          className={
                            preview.image ? "set-show-preview" : "show-preview"
                          }
                        ></img> */}
                              </div>
                            </label>
                            <input
                              type=""
                              className="image-file-y"
                              // onChange={fileHandler}
                              required
                              onClick={propertyOutModal}
                            />
                          </div>
                          <p>
                            Upload Work <br /> Photos
                          </p>
                        </div>
                        <div className="up-btn">
                          <button
                            type="button"
                            className="select"
                            onClick={propertyOutModal}
                          >
                            Upload
                            <span>
                              <img
                                className="plus"
                                src={
                                  config.appBaseName + "images/white-plus.png"
                                }
                                alt=""
                              />
                            </span>
                          </button>
                        </div>
                      </form>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="col-lg-6 col-12 calender-row">
                <div className="calender">
                  <h3 className="attend-calender">Attendance Calendar</h3>
                  <img
                    className="plus"
                    src={config.appBaseName + "images/white-plus.png"}
                    alt=""
                  />
                  <img
                    className="down-arrow"
                    src={config.appBaseName + "images/down-arrow.png"}
                    alt=""
                  />
                  <hr />
                  <Calendar
                    onChange={handleUserClockInOut}
                    value={startDate}
                    formatLongDate={(locale, date) =>
                      dayjs(date).format("MMM D, YYYY")
                    }
                    showNeighboringMonth={false}
                  />
                  <div className="pre_abs">
                    <div className="present">
                      <h3>Present</h3>
                    </div>
                    <div className="absent">
                      <h3>Absent</h3>
                    </div>
                  </div>
                </div>
                {isClockIn &&
                isClockIn.is_clock_in !== isClockIn.is_clock_out ? (
                  <div className="upload-y upload-work">
                    <form onSubmit={handleSubmit(queryImages)}>
                      <div className="priview-y">
                        <div className="house-wrapper-y">
                          <label className="property-from-label-y">
                            <img
                              className="sell-property-image-y"
                              src={
                                config.appBaseName + "images/preview_img.png"
                              }
                              alt=""
                            />
                            {/* <div className="centered-text ">
            <FaPlus className="plus-icon" />
          </div> */}
                            <div className="picture-preview-y">
                              <img
                                id="imgQueryPreview"
                                className="set-show-preview"
                                src={
                                  config.appBaseName + "images/preview_img.png"
                                }
                                alt=""
                              />
                              {/* {workPreview.src ? (
                            <img
                              className="set-show-preview"
                              src={workPreview.src}
                              alt={workPreview.src}
                            />
                          ) : (
                            ""
                          )} */}
                            </div>
                          </label>
                          <input
                            id="queryfile"
                            name="queryImages"
                            type="file"
                            accept="image/jpeg,image/png"
                            className="image-file-y"
                            // onChange={fileworkHandler}
                            required
                          />
                        </div>
                        <p>
                          Please upload violation <br /> photos here
                        </p>
                      </div>
                      <div className="up-btn">
                        <button type="submit" className="select">
                          Upload
                          <span>
                            <img
                              className="plus"
                              src={config.appBaseName + "images/white-plus.png"}
                              alt=""
                            />
                          </span>
                        </button>
                      </div>
                    </form>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </>
      )}

      {/* clockInModal */}
      <div className="map-modal">
        <Modal
          ariaHideApp={false}
          isOpen={modalClockIn}
          contentLabel="Example Modal"
        >
          <div id="loadSpins" className="spinner " role="status" hidden>
            <span className="spinner-border"></span>
          </div>
          <div className="map-modal hide-modal">
            <img
              src={config.appBaseName + "images/close-btn.png"}
              onClick={closeClockInModal}
              className="modal-close"
              alt=""
            />
            <div className="g-map">
              <GoogleMap
                mapContainerStyle={mapStyles}
                zoom={13}
                center={defaultCenter}
              >
                <Marker
                  icon={config.appBaseName + "images/pin.png"}
                  key="marker_1"
                  position={defaultCenter}
                />
              </GoogleMap>
            </div>
            <div className="select" onClick={getLocation}>
              Use My Current Location
              <span>
                <img
                  className="plus"
                  src={config.appBaseName + "images/white-plus.png"}
                  alt=""
                />
              </span>
            </div>

            <div className="modal-form login-form ">
              <form onSubmit={handleSubmit(clockIn)}>
                <select
                  name=""
                  id="type"
                  onChange={(e) => setService(e.target.value)}
                  required
                >
                  <option value="" selected hidden>
                    Select Services
                  </option>
                  {serviceData &&
                    serviceData.map((item, i) => {
                      return (
                        <option key={i} value={item.service}>
                          {item.service}
                        </option>
                      );
                    })}
                </select>
                {/* <input
                  id="property"
                  type="text"
                  name="property_name"
                  placeholder="Property Name"
                  value={property}
                  onChange={(e) => setProperty(e.target.value)}
                  required
                /> */}
                {/* <input
                  id="building"
                  type="text"
                  name="building_name"
                  placeholder="Building Name"
                  value={building}
                  onChange={(e) => setBuilding(e.target.value)}
                  required
                /> */}
                {/* <input
                  id="unit"
                  type="text"
                  placeholder="Unit Number"
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  name="unit_number"
                  value={unit}
                  onChange={(e) => setUnit(e.target.value)}
                  required
                /> */}

                <button type="text" className="select">
                  Clock In
                  <span>
                    <img
                      className="plus"
                      src={config.appBaseName + "images/white-plus.png"}
                      alt=""
                    />
                  </span>
                </button>
              </form>
            </div>
          </div>
        </Modal>
      </div>

      {/* clockOutModal */}
      <div className="map-modal">
        <Modal
          ariaHideApp={false}
          isOpen={modalClockOut}
          contentLabel="Example Modal"
        >
          <div id="loadSpins" className="spinner " role="status" hidden>
            <span className="spinner-border"></span>
          </div>

          <div className="map-modal hide-modal">
            <img
              src={config.appBaseName + "images/close-btn.png"}
              onClick={closeClockOutModal}
              className="modal-close"
              alt=""
            />
            <div className="g-map">
              <GoogleMap
                mapContainerStyle={mapStyles}
                zoom={13}
                center={defaultCenter}
              >
                <Marker
                  icon={config.appBaseName + "images/pin.png"}
                  key="marker_1"
                  position={defaultCenter}
                />
              </GoogleMap>
            </div>
            <div className="select" onClick={getLocation}>
              Use My Current Location
              <span>
                <img
                  className="plus"
                  src={config.appBaseName + "images/white-plus.png"}
                  alt=""
                />
              </span>
            </div>

            <div className="modal-form login-form ">
              <form onSubmit={handleSubmit(ServicesImages)}>
                <div className="uplod-photo">
                  {serviceData &&
                    serviceData.map((item, i) => {
                      if (
                        item.service ===
                        (user &&
                          user.clocking_dtl &&
                          user.clocking_dtl.services)
                      ) {
                        let listLength = [];
                        return (
                          <div key={i}>
                            {item &&
                              item.sub_service.map((list, i) => {
                                if (
                                  list.is_selected === "1" ||
                                  list.is_selected === 1
                                ) {
                                  listLength.push(list.sub_id);
                                  return (
                                    <>
                                      {list.name === "Tracker" ? (
                                        list.property &&
                                        list.property.map((list, i) => (
                                          <React.Fragment key={i}>
                                            <div>
                                              <label for="uploadPhoto1">
                                                Upload <span>{list.name}</span>{" "}
                                                Photos
                                              </label>
                                              <div className="d-flex">
                                                <input
                                                  type="file"
                                                  name="sub_service_img"
                                                  id={`uploadPhoto${i}tracker`}
                                                  accept="image/jpeg,image/png"
                                                  className="mt-1 mb-4 me-2"
                                                  required
                                                />
                                                <input
                                                  type="text"
                                                  name="desc"
                                                  id={`desc${i}tracker`}
                                                  className="mt-1 mb-4"
                                                  placeholder="Enter description"
                                                  required
                                                />
                                              </div>
                                            </div>
                                            {fields &&
                                              fields.map((item, index) => {
                                                return (
                                                  <>
                                                    {item.name == list.name && (
                                                      <div className="d-flex">
                                                        <input
                                                          type="file"
                                                          name="sub_service_img"
                                                          id={`uploadPhoto${item.id}tracker`}
                                                          accept="image/jpeg,image/png"
                                                          className="mt-1 mb-4 me-2"
                                                          required
                                                        />
                                                        <input
                                                          type="text"
                                                          name="desc"
                                                          id={`desc${item.id}tracker`}
                                                          className="mt-1 mb-4"
                                                          placeholder="Enter description"
                                                          required
                                                        />
                                                        <button
                                                          type="button"
                                                          className="remove"
                                                          onClick={() => {
                                                            deleteField(
                                                              item.id
                                                            );
                                                          }}
                                                        >
                                                          ×
                                                        </button>
                                                      </div>
                                                    )}
                                                  </>
                                                );
                                              })}

                                            <button
                                              type="button"
                                              className="select add-more"
                                              onClick={() => {
                                                addField(
                                                  list.name,
                                                  item.sub_service.length
                                                );
                                              }}
                                            >
                                              Add more photos
                                            </button>
                                          </React.Fragment>
                                        ))
                                      ) : (
                                        <React.Fragment key={i}>
                                          <div>
                                            <label for="uploadPhoto1">
                                              Upload <span>{list.name}</span>{" "}
                                              Photos
                                            </label>
                                            <div className="d-flex">
                                              <input
                                                type="file"
                                                name="sub_service_img"
                                                id={`uploadPhoto${i + 1}`}
                                                accept="image/jpeg,image/png"
                                                className="mt-1 mb-4 me-2"
                                                required
                                              />
                                              <input
                                                type="text"
                                                name="desc"
                                                id={`desc${i}`}
                                                className="mt-1 mb-4"
                                                placeholder="Enter description"
                                                required
                                              />
                                            </div>
                                          </div>
                                          {fields &&
                                            fields.map((item, index) => {
                                              return (
                                                <>
                                                  {item.name == list.name && (
                                                    <div className="d-flex">
                                                      <input
                                                        type="file"
                                                        name="sub_service_img"
                                                        id={`uploadPhoto${item.id}`}
                                                        accept="image/jpeg,image/png"
                                                        className="mt-1 mb-4 me-2"
                                                        required
                                                      />
                                                      <input
                                                        type="text"
                                                        name="desc"
                                                        id={`desc${item.id}`}
                                                        className="mt-1 mb-4"
                                                        placeholder="Enter description"
                                                        required
                                                      />
                                                      <button
                                                        type="button"
                                                        className="remove"
                                                        onClick={() => {
                                                          deleteField(item.id);
                                                        }}
                                                      >
                                                        ×
                                                      </button>
                                                    </div>
                                                  )}
                                                </>
                                              );
                                            })}

                                          <button
                                            type="button"
                                            className="select add-more"
                                            onClick={() => {
                                              addField(
                                                list.name,
                                                item.sub_service.length
                                              );
                                            }}
                                          >
                                            Add more photos
                                          </button>
                                        </React.Fragment>
                                      )}
                                    </>
                                  );
                                }
                              })}

                            {item &&
                              item.sub_service.map((list, i) => {
                                if (listLength.length === 0) {
                                  return (
                                    <React.Fragment key={i}>
                                      <div>
                                        <label for="uploadPhoto1">
                                          Upload <span>{list.name} </span>{" "}
                                          Photos
                                        </label>
                                        <div className="d-flex">
                                          <input
                                            type="file"
                                            name="sub_service_img"
                                            id={`uploadPhoto${i}`}
                                            accept="image/jpeg,image/png"
                                            className="mt-1 mb-4 me-2"
                                            required
                                          />
                                          <input
                                            type="text"
                                            name="desc"
                                            id={`desc${i}`}
                                            className="mt-1 mb-4"
                                            placeholder="Enter description"
                                            required
                                          />
                                        </div>
                                      </div>
                                      {fields &&
                                        fields.map((item, index) => {
                                          return (
                                            <>
                                              {item.name == list.name && (
                                                <div className="d-flex">
                                                  <input
                                                    type="file"
                                                    name="sub_service_img"
                                                    id={`uploadPhoto${item.id}`}
                                                    accept="image/jpeg,image/png"
                                                    className="mt-1 mb-4 me-2"
                                                    required
                                                  />
                                                  <input
                                                    type="text"
                                                    name="desc"
                                                    id={`desc${item.id}`}
                                                    className="mt-1 mb-4 "
                                                    placeholder="Enter description"
                                                    required
                                                  />
                                                  <button
                                                    type="button"
                                                    className="remove"
                                                    onClick={() => {
                                                      deleteField(item.id);
                                                    }}
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                              )}
                                            </>
                                          );
                                        })}

                                      <button
                                        type="button"
                                        className="select add-more"
                                        onClick={() => {
                                          addField(
                                            list.name,
                                            item.sub_service.length
                                          );
                                        }}
                                      >
                                        Add more photos
                                      </button>
                                    </React.Fragment>
                                  );
                                }
                              })}
                          </div>
                        );
                      }
                    })}
                </div>

                <button type="text" className="select">
                  Clock Out
                  <span>
                    <img
                      className="plus"
                      src={config.appBaseName + "images/white-plus.png"}
                      alt=""
                    />
                  </span>
                </button>
              </form>
            </div>
          </div>
        </Modal>
      </div>

      {/* clockInProperty */}
      <div className="map-modal">
        <Modal
          ariaHideApp={false}
          isOpen={propertyModal}
          contentLabel="Example Modal"
          id="clock-in-property"
        >
          <div id="loadSpins" className="spinner " role="status" hidden>
            <span className="spinner-border"></span>
          </div>
          <img
            src={config.appBaseName + "images/close-btn.png"}
            onClick={closePropertyModal}
            className="modal-close"
            alt=""
          />
          <div className="map-modal upload-modal hide-modal">
            <div className="modal-form login-form ">
              <form onSubmit={clockInProperty}>
                <select
                  name=""
                  id="type"
                  onChange={(e) => setSelectProperty(e.target.value)}
                  required
                >
                  <option value="" selected hidden>
                    Select Property
                  </option>
                  {propertyData &&
                    propertyData.map((item, i) => {
                      return (
                        <option key={i} value={item.property_name}>
                          {item.property_name}
                        </option>
                      );
                    })}
                </select>

                <button type="submit" className="select orange-bg">
                  Next
                  <span>
                    <img
                      className="plus"
                      src={config.appBaseName + "images/white-plus.png"}
                      alt=""
                    />
                  </span>
                </button>
              </form>
            </div>
          </div>
        </Modal>
      </div>

      {/* clockOutProperty */}
      <div className="map-modal">
        <Modal
          ariaHideApp={false}
          isOpen={propertyOut}
          contentLabel="Example Modal"
          id="clock-out-property"
        >
          <div id="loadSpins" className="spinner " role="status" hidden>
            <span className="spinner-border"></span>
          </div>
          <img
            src={config.appBaseName + "images/close-btn.png"}
            onClick={closePropertyOutModal}
            className="modal-close"
            alt=""
          />
          <div className="map-modal upload-modal hide-modal">
            <div className="modal-form login-form ">
              <form onSubmit={clockOutProperty}>
                <select
                  name=""
                  id="type"
                  onChange={(e) => setSelectProperty(e.target.value)}
                  required
                >
                  <option value="" selected hidden>
                    Select Property
                  </option>
                  {propertyData &&
                    propertyData.map((item, i) => {
                      return (
                        <option key={i} value={item.property_name}>
                          {item.property_name}
                        </option>
                      );
                    })}
                </select>
                <button type="submit" className="select orange-bg">
                  Next
                  <span>
                    <img
                      className="plus"
                      src={config.appBaseName + "images/white-plus.png"}
                      alt=""
                    />
                  </span>
                </button>
              </form>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default ClockCalender;
